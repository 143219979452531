<ng-container *ngIf="!costingAllocation">
    <div class="d-flex justify-content-center align-items-center mt-4 text-muted">
        No costing info available for this Sales Order
</div>
</ng-container>
<ng-container *ngIf="costingAllocation && costingAllocation.dirtyFlag">
 <div class="d-flex justify-content-center align-items-center mt-4">
    <div>
        <span *ngIf="costingAllocation.salesOrderSubmissionCount!==1" >There has been changes to order since last costing. Please Re-evalute.</span>
        <span *ngIf="costingAllocation.salesOrderSubmissionCount===1" >Costing Info Not Available</span>
        <div class="d-flex justify-content-center align-items-center mt-4">
            <button class="btn btn-sm btn-danger ml-3" (click)="edit()" 
                                    *ngIf="userAccess?.canEditCostingAllocation && !editable  
                                            && (salesOrder.productionStatus===productionStatusForCostingPending)" >
                                            {{costingAllocation.salesOrderSubmissionCount===1?'Start Costing':'Re-Evaluate'}}</button>
        </div>
    </div>
 </div>
</ng-container>
<ng-container *ngIf="costingAllocation && !costingAllocation.dirtyFlag ">
    <!--Summary-->
    <mat-accordion  class="px-2" *ngIf="((userAccess?.canViewCostingSummary && salesOrder.orderType !=='Estimation') || (userAccess?.canViewEstimationCostingSummary && salesOrder.orderType ==='Estimation'))">
        <mat-expansion-panel [expanded]="salesOrder.productionStatus!==productionStatusForCostingPending" class="border-info border">
            <mat-expansion-panel-header style="    background: cadetblue;
            color: white;" [collapsedHeight]="'40px'" [expandedHeight]="'40px'" [style.background]="salesOrder.orderType ==='Estimation'? 'rgba(8, 178, 201, 0.30)': 'cadetblue'">
                <mat-panel-title [class]="salesOrder.orderType ==='Estimation'?'text-dark':'text-white'">
                    {{salesOrder.orderType ==='Estimation'? 'Estimation Costing Summary': 'Costing Summary'}}
                </mat-panel-title>
                <!--<mat-panel-description>
                    This is a summary of the content
                </mat-panel-description>-->
            </mat-expansion-panel-header>
            <div>
                
    <div class="overflow-hidden" style="min-height: 80vh;" >
        <div class="row">
               <div class="col-sm-9">
                   <ng-container><!-- *ngIf="!editable"-->
                       <ng-container *ngFor="let styleCosting of costingAllocation.styleCosting; index as casci">
                       <div class="row  mt-1">
                           <div class="col-sm-auto ">
                               <div class="card mx-2" style="width: 16rem; border: 0px;">
                                   <div class="card-body p-2">
                                    <!--<span class="f-10 badge badge-pill color-0 mb-1" style="width: 50% ;font-weight: 500!important;" *ngIf="styleCosting.repeatOrder && userAccess?.canViewRepeatOrderDetails" (click)="showRepeatOrderDetails(salesOrder,styleCosting)" >Repeat Order</span>
                                    <span>{{showMismatchWithRepeatOrderDetails(salesOrder,styleCosting)}}</span> -->
                                       <div class=" d-flex align-items-center">
                                           <div>
                                               <div style="position: relative;">
                                                   <div style="position: absolute;" *ngIf="styleCosting.status==='CANCELLED'" >
                                                       <img src="assets/cancelledBanner.png" 
                                                           class="" width="auto" height="70">
                                                   </div>
                                                   <img [src]="styleCosting.product?.productImages[0]?.thumbnailSignedUrl" 
                                                   class="img-thumbnail img-fluid "
                                                   style="display: block;  width:60px;  height:100px; min-width: 100px;"
                                                   [style.border-color]="'transparent'"
                                                   (error)="handleThumbnailError(styleCosting.product?.productImages[0],$event)" />
                                               </div>
                                               
                                           </div>
                                           <div class="ml-1 mt-1">
                                               <div class="d-flex flex-column f-11">
                                                   <label class="" style="font-weight: 700;">{{styleCosting.sku}}</label>
                                                   <label class="mb-0">Qty : {{styleCosting.orderQty}}</label>
                                                   <label class="mb-0">Sample Qty : {{styleCosting.totalSampleQty}}</label>
                                                   <label *ngIf="costingMode==='REGULAR'" class="mb-0">Sale Price / EA : {{styleCosting.sellingPricePerUnit}}</label>
                                                   <label *ngIf="costingMode==='EXPLORE'" class="mb-0">Sale Price / EA : {{styleCosting.originalSellingPricePerUnit}}</label>
                                                   <div *ngIf="(salesOrder.orderType ==='Estimation') || (costingMode==='EXPLORE')">
                                                      <label class="mb-0">Suggested Sale Price / EA :
                                                      <!--<span>
                                                         <input name="suggestedPrice_{{casci}}" type="number" class="form-control form-control-sm " style="font-size: 11px;" #suggestedPriceVar="ngModel" required [disabled]="!editable"
                                                            [(ngModel)]="styleCosting.suggestedSellingPricePerUnit" [ngClass]="{'is-invalid':suggestedPriceVar.touched && suggestedPriceVar.errors}" [customMin]="0"
                                                            (keyup)="onSuggestedSellingPriceChange(styleCosting, costingAllocation)">
                                                      </span>-->
                                                      <span>
                                                         <input name="sellingPricePerUnit_{{casci}}" type="number" class="form-control form-control-sm " style="font-size: 11px;" #sellingPriceVar="ngModel" required [disabled]="!editable"
                                                            [(ngModel)]="styleCosting.sellingPricePerUnit" [ngClass]="{'is-invalid':sellingPriceVar.touched && sellingPriceVar.errors}" [customMin]="0"
                                                            (keyup)="onSellingPriceChange(styleCosting, costingAllocation)">
                                                      </span>
                                                   </label>
                                                   </div>
                                                   <label class="mb-0">Total Sale : {{(styleCosting.sellingPricePerUnit * styleCosting.orderQty) | number: '1.0-0'}}</label>
                                                   <ng-container *ngFor="let ctd of styleCosting.costTypeDetails; index as sumctdi">
                                                    <div class="mb-1" *ngIf="ctd.description==='Royalty'">
                                                        <div >
                                                            <label class="mb-0 " style="font-size: .60rem; color: lightslategray;">Royalty(%)</label>
                                                            <div style="width: 50%;">
                                                                <div class="d-flex align-items-center">
                                                                    
                                                                    <input name="sumctd_{{casci}}_{{sumctdi}}" type="number" class="form-control form-control-sm " style="font-size: 11px;" #ctdVar="ngModel" required 
                                                                    [disabled]="!editable"
                                                                            [(ngModel)]="ctd.uiCost" [ngClass]="{'is-invalid':ctdVar.touched && ctdVar.errors}" [customMin]="0"
                                                                            (change)="calculateAllocationSheet(costingAllocation)">
                                                                    <span *ngIf="ctd.description==='Royalty' && ctd.uiCost!==ctd.defaultCost" class="pl-1" ><i class="fas fa-exclamation-circle text-danger"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   </ng-container>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="d-flex justify-content-center">
                                       <a target="_blank" [routerLink]="['/costingsheet/'+salesOrderId+'/'+styleCosting.orderDetailId]" class="card-link f-11">View Costing Sheet</a>
                                       </div>
                                       <div *ngIf="styleCosting.costingSheetGenerationErrors">
                                           <div *ngFor="let error of styleCosting.costingSheetGenerationErrors">
                                               <span class="f-10 text-danger">{{error}}</span>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div class="col-sm-auto d-flex justify-content-end">
                               <div class="card-deck ">
                                   
                                   <ng-container *ngFor="let costingSheet of styleCosting.costingSheets; index as casccsi">
                                       
                                       <div class="card my-1" style="width: 16rem;" [style.background]="costingSheet.approvalStatus==='APPROVED'?'aquamarine':''" (click)="costingSheetApprovalChanged(styleCosting,costingSheet)">
                                           <div class="card-body p-2" >
                                               <h6 class="card-title mb-1"  [style.color]="costingSheet.grossProfit &lt; 0 ?'red':'green'">{{costingSheet.grossProfitPercentage}} %</h6>
                                               <h6 class="card-subtitle mt-2 f-13" [style.color]="costingSheet.grossProfit &lt; 0 ?'red':''">Gross Profit: {{costingAllocation.orderCurrency}} {{costingSheet.grossProfit}}</h6>
                                               <div class="d-flex  mt-2">
                                                   <div >
                                                       <label class="mb-0 " style="font-size: .60rem; color: lightslategray;">Printer</label>
                                                       <div>
                                                           <span  style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{costingSheet.allocatedPrinterNames}}</span>
                                                       </div>
                                                   </div>
                                                   <div>&nbsp;</div>
                                                   <div >
                                                       <label class="mb-0 " style="font-size: .60rem; color: lightslategray;">Supplier</label>
                                                       <div>
                                                           <span *ngIf="!costingSheet.fullPackageOrder" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{costingSheet.allocatedBlankSupplierNames}}</span>
                                                           <span  *ngIf="costingSheet.fullPackageOrder" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;"> ( Full Package )</span>
                                                       </div>
                                                   </div>
                                               </div>
                                               <div>
                                                <span *ngIf="costingSheet.additionalNotes" style="background: transparent; font-size: 10px; padding-left: .20rem;border-width: 0px;">{{costingSheet.additionalNotes}}</span>
                                               </div>

                                           </div>
                                           
                                                                                          </div>
                                   
                                   </ng-container>
                               
                               </div>
                           </div>
                           
                       </div>
                       <hr>
                       </ng-container>
                   </ng-container> 
               </div>
               <div class="col-sm-3  border-left border-secondary  " >
                   <div class="mr-1 pr-2 h-100">
                       <div class="card  border-info my-1" style="min-width: 16rem;">
                           <div class="card-body p-2">
                               <h4 class="card-title mb-1" [style.color]="costingAllocation?.totalGrossProfit &lt; 0 ?'red':'green'">
                                   Gross Profit (%): {{costingAllocation?.totalGrossProfitPercentage | number:'1.2-2'}} %</h4>
                               <h5 class="card-subtitle mt-2 f-13" [style.color]="costingAllocation?.totalGrossProfit &lt; 0 ?'red':''">
                                   GROSS PROFIT : {{costingAllocation?.totalGrossProfit | number:'1.2-2'}}</h5>
                                   <div class="card-text f-12 mt-2">
                                       Total Revenue : {{costingAllocation?.totalRevenue | number:'1.2-2'}}
                                   </div>
                                   <div class="card-text f-12 mt-2">
                                       Total Cost : {{costingAllocation?.totalCost | number:'1.2-2'}}
                                   </div>
                                   <div class="card-text f-12 mt-2">
                                       Total Quantity : {{costingAllocation?.totalQuantity}}
                                  </div>                                   
                                   <div class="d-flex">
                                       <div>
                                           <label class="mb-0" style="font-size: .60rem; color: lightslategray;">Styles Approved</label>
                                           <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{costingAllocation?.stylesApproved}}</span>
                                       </div>
                                       <div>&nbsp;</div>
                                       <div>
                                           <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Styles Pending</label>
                                           <span class="form-control form-control-sm" style="background: transparent; font-size: 13px; padding-left: .30rem;border-width: 0px;">{{costingAllocation?.stylesPending}}</span>
                                       </div>
                                       <div>&nbsp;</div>
                                       <ng-container *ngFor="let ctd of costingAllocation.orgCostTypeDetails; index as gpctdi">
                                        <div class="mb-1" *ngIf="ctd.description==='Sales Commission'">
                                            <div >
                                                <label class="mb-0 " style="font-size: .60rem; color: lightslategray;">Sales Commission(%)</label>
                                                <div style="width: 50%;">
                                                    <input name="gpcstd_{{gpctdi}}" type="number" class="form-control form-control-sm " style="font-size: 11px;" #gpctdVar="ngModel" required 
                                                    [disabled]="!editable"
                                                            [(ngModel)]="ctd.uiCost" [ngClass]="{'is-invalid':gpctdVar.touched && gpctdVar.errors}" [customMin]="0"
                                                            (change)="calculateAllocationSheet(costingAllocation)">
                                                </div>
                                            </div>
                                        </div>
                                       </ng-container>
                                       
                                   </div>
                           </div>
                           <div class="card-footer">
                               <div>
                                <ng-container *ngIf="!editable && salesOrder.orderType !=='Estimation'">
                                   <button class="btn btn-success btn-block" type="button"
                                   *ngIf="userAccess?.canApproveCosting && costingAllocation.approvalStatus!=='APPROVED' && salesOrder.productionStatus===productionStatusForCostingPendingApproval" 
                                   [disabled]="!costingAllocation.allStylesApproved" (click)="approveSalesOrderCosting()">Approve Costing</button>

                                   <button class="btn btn-warning btn-block" type="button"
                                   *ngIf="userAccess?.canApproveCosting &&  costingAllocation.approvalStatus!=='APPROVED' && salesOrder.productionStatus===productionStatusForCostingPendingApproval" 
                                   (click)="unapproveSalesOrderCosting()">Send for Re-Costing</button>
                                </ng-container>

                                <ng-container *ngIf="!editable && salesOrder.orderType ==='Estimation'">
                                    <button class="btn btn-success btn-block" type="button"
                                   *ngIf="userAccess?.canAcknowledgeEstimation &&  salesOrder.productionStatus===productionStatusForCostingPendingApproval" 
                                    (click)="acknowledgeEstimation()">Acknowledge Estimation</button>

                                    <button class="btn btn-warning btn-block" type="button"
                                    *ngIf="userAccess?.canEditEstimationCostingAllocation &&   (salesOrder.productionStatus===productionStatusForEstimationAcknowledgment || salesOrder.productionStatus===productionStatusForCostingPendingApproval)" 
                                    (click)="unapproveSalesOrderCosting()">Send for Re-Estimation</button>
                                 </ng-container>
                                 
                                <ng-container *ngIf="editable && salesOrder.orderType !=='Estimation' && salesOrder.productionStatus===productionStatusForCostingPendingApproval">
                                    <p class="text-danger f-11">** Save the costing sheet to proceed with costing approval</p>
                                </ng-container>

                                   <button class="btn btn-danger btn-block" type="button"
                                   *ngIf="costingAllocation.approvalStatus==='APPROVED' && userAccess?.isSupportAdmin" [disabled]="!costingAllocation.allStylesApproved" (click)="unapproveSalesOrderCosting()">Unapprove Costing</button>
                               </div>
                           </div>
                           
                           
                       </div>
                       <ng-container *ngIf="salesOrder.orderType !=='Estimation'">
                            <div style="max-width: calc(70vw);" class="border-info pl-2" *ngIf="userAccess?.canEditCostingAllocation">
                                <app-notes [editable]="true" [vh]="60" [parentId]="costingAllocation.salesOrderId" [type]="'CA_APPROVAL_NOTES'" [subType]="'SUMMARY'" [notesHeaderText]="'Costing Notes'" [notesSubject]="'Costing Approval Notes for CS# '+ salesOrder.salesOrderNumber"></app-notes>  
                            </div>
                        </ng-container>

                        <ng-container *ngIf="salesOrder.orderType ==='Estimation'">
                            <div style="max-width: calc(70vw);" class="border-info pl-2" *ngIf="userAccess?.canEditEstimationCostingAllocation">
                                <app-notes [editable]="true" [vh]="60" [parentId]="costingAllocation.salesOrderId" [type]="'COSTING_ESTIMATION_NOTES'" [subType]="'SUMMARY'" [notesHeaderText]="'Estimation Notes'" [notesSubject]="'Estimation Notes for CS# '+ salesOrder.salesOrderNumber"></app-notes>  
                            </div>
                        </ng-container>
                   </div>
               </div>
               
               
           </div>
   </div>
</div>
</mat-expansion-panel>
</mat-accordion>
    <!--Summary Ends-->

    <!--Preview STarts-->
   <ng-container *ngIf="salesOrder.orderType !=='Estimation'">
      <mat-accordion class="px-2" *ngIf="userAccess?.canViewCostingSummary && (salesOrder.productionStatus===productionStatusForCostingPendingApproval || salesOrder.productionStatus===productionStatusForCostingPending)">
         <mat-expansion-panel [expanded]="false" (opened)="reloadBlankSummaryPreview()" class="border-info border">
            <mat-expansion-panel-header style="    background: cadetblue;
                color: white;" [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
               <mat-panel-title [class]="salesOrder.orderType ==='Estimation'?'text-dark':'text-white'">
                  Blanks Summary Preview
               </mat-panel-title>
               <!--<mat-panel-description>
                        This is a summary of the content
                    </mat-panel-description>-->
            </mat-expansion-panel-header>
            <div>
   
               <div class="overflow-hidden" style="min-height: 80vh;">
                  <app-blank-summary [events]="blankSummaryEventsSubject.asObservable()" [salesOrderNumber]="salesOrder.salesOrderNumber" [salesOrderId]="salesOrder.id"
                     [costingAllocationForPreview]="costingAllocation" [previewOnly]="(salesOrder.productionStatus===productionStatusForCostingPendingApproval || salesOrder.productionStatus===productionStatusForCostingPending)?true:false"></app-blank-summary>
               </div>
            </div>
         </mat-expansion-panel>
      </mat-accordion>
   </ng-container>
    <!--Preview Ends-->
    
    <mat-accordion  class="px-2" >
        <mat-expansion-panel hideToggle class="" disabled>
            <mat-expansion-panel-header style="    background: cadetblue; 
            color: white;" [collapsedHeight]="'40px'" [expandedHeight]="'40px'" [style.background]="salesOrder.orderType ==='Estimation'? 'rgba(8, 178, 201, 0.30)': 'cadetblue'">
                <mat-panel-title [class]="salesOrder.orderType ==='Estimation'?'text-dark':'text-white'">
                  {{salesOrder.orderType ==='Estimation'? 'Estimation Costing Allocation': 'Allocation'}}  <span *ngIf="salesOrder.flagged">&nbsp;&nbsp;<i class="fas fa-flag" style="color:red"></i></span>
                </mat-panel-title>
                <!--<mat-panel-description>
                    This is a summary of the content
                </mat-panel-description>-->
            </mat-expansion-panel-header>
        </mat-expansion-panel>
    </mat-accordion>
    
    
            <div class="overflow-hidden" *ngIf="((userAccess?.canViewCostingAllocation && salesOrder.orderType !=='Estimation') || (userAccess?.canViewEstimationCosting && salesOrder.orderType ==='Estimation'))">
                <form #costingAllocationForm="ngForm"  autocomplete="off"  novalidate>
                    <!---->

    


                    

                        <!--<div class="m-2 d-flex justify-content-end">-->
                            
                        <div class="floatBtn d-flex flex-column">
                            <!--<button class="btn btn-sm btn-primary ml-3" (click)="loadPrinterSelectionDialog()" *ngIf="editable">+ Printer</button>
                            <button class="btn btn-sm btn-primary ml-3" (click)="loadBlankSupplierSelectionDialog()" *ngIf="editable">+ Blank Supplier</button>
                            -->
                            <ng-container 
                            *ngIf="(
                                (salesOrder.orderType!=='Estimation' 
                                && (
                                        salesOrder.productionStatus===productionStatusForCostingPendingApproval 
                                        || salesOrder.productionStatus===productionStatusForCostingApproved
                                        || salesOrder.productionStatus===productionStatusForCostingPending 
                                    )
                                )
                                || (salesOrder.orderType==='Estimation')
                            )">
                              <button *ngIf="costingMode === 'REGULAR' && !editable"  class="btn btn-circle mb-2 bg-warning" type="button" (click)="exploreCosting()"  matTooltip="Adjust figures" matTooltipPosition="left" matTooltipClass="bg-dark">
                                 <i class="fas fa-sliders-h fa-2x" style="color: white;"></i><!-- <i class="fas fa-calculator fa-lightbulb-on fa-2x" style="color:white"></i> -->
                              </button>

                              <button class="btn btn-circle mb-2 btn-dark" type="button" (click)="cancelExploreCosting()" *ngIf="costingMode !== 'REGULAR' && editable">
                                 <i class="fas fa-times fa-2x" style="color:white"></i>
                           </button>       
                           </ng-container>                    
                           <ng-container *ngIf="costingMode === 'REGULAR'">
                            <button class="btn btn-circle bg-dark mb-2" type="button" (click)="edit()" 
                            *ngIf="!editable  
                                    && (
                                            userAccess?.canEditCostingAllocation 
                                            || (userAccess?.canEditEstimationCostingAllocation && salesOrder.orderType ==='Estimation')
                                        )
                                    && (salesOrder.productionStatus===productionStatusForCostingPending 
                                        || salesOrder.productionStatus===productionStatusForCostingPendingApproval
                                        )" matTooltip="Edit Costing" matTooltipPosition="left" matTooltipClass="bg-dark">
                                    <i class="fas fa-pen fa-lg" style="color: white;"></i>
                                </button>
                            <button class="btn btn-circle mb-2 bg-info " type="button" (click)="submitCostingForApproval()" matTooltip="Submit for Approval" matTooltipPosition="left" matTooltipClass="bg-dark"
                            *ngIf="!costingAllocation.invalid 
                                && (userAccess?.canSubmitCostingAllocation 
                                    || (userAccess?.canSubmitEstimationCostingAllocation && salesOrder.orderType ==='Estimation')
                                     ) 
                                && !editable && costingAllocation.approvalStatus!=='APPROVED' 
                                    && (salesOrder.productionStatus===productionStatusForCostingPending)" >
                                    <i class="fas fa-sign-in-alt fa-2x" style="color:white;"></i>
                                </button>
<!--saveCostingAllocation()--><button class="btn btn-circle mb-2 bg-info" type="button" (click)="generateCostingSheetsCombinationForEachStyle(true);"  *ngIf="editable && costingAllocationForm.valid"
                                matTooltip="Save Costing" matTooltipPosition="left" matTooltipClass="bg-dark"    >
                                <i class="fas fa-save fa-2x" style="color:white"></i>
                            </button>
                            
                            <button class="btn btn-circle mb-2 bg-warning" type="button" (click)="validateCostingAllocationForm();"  *ngIf="editable && !costingAllocationForm.valid"
                                matTooltip="Validate Costing" matTooltipPosition="left" matTooltipClass="bg-dark"    >
                                <i class="fas fa-save fa-2x" style="color:white"></i>
                            </button>
                            <button class="btn btn-circle mb-2 bg-secondary" type="button" (click)="saveCostingAllocationAsDraft();"  *ngIf="editable && !costingAllocationForm.valid && userAccess?.canSaveDraftCostingAllocation  && (salesOrder.productionStatus===productionStatusForCostingPending)"
                                matTooltip="Save As Draft" matTooltipPosition="left" matTooltipClass="bg-dark"    >
                                <i class="fab fa-firstdraft fa-2x" style="color:white"></i>
                            </button>
                            <button class="btn btn-circle mb-2 btn-dark" type="button" (click)="cancelEdit()" *ngIf="editable">
                                <i class="fas fa-times fa-2x" style="color:white"></i>
                            </button>
                           </ng-container>
                        </div>
                   
                        <table class="table table-sm f-10 table-bordered" style="width: 100%;" 
                        *ngIf="costingAllocation.allocatedSuppliers && costingAllocation.allocatedSuppliers.length>0
                        && costingAllocation.allocatedSuppliers[0].costTypeDetails
                        && costingAllocation.allocatedSuppliers[0].costTypeDetails.length>0
                        && getUserInputCostTypeDetailsCount(costingAllocation.allocatedSuppliers[0].costTypeDetails)>0">
                            <thead style="background: lightblue;">
                                <tr>
                                    <th style="width: 10%;">Blank Suppliers Allocated</th>
                                    <ng-container *ngFor="let ctd of  costingAllocation.allocatedSuppliers[0].costTypeDetails">
                                        <th *ngIf="ctd.source==='USER_INPUT'">{{ctd.description}}
                                            <div>
                                                <span *ngIf="ctd.calculationType==='PERCENTAGE'">(%)</span>
                                                <span *ngIf="ctd.calculationType==='ABSOLUTE_UNIT'">($ / EA)</span>
                                                <span *ngIf="ctd.calculationType==='ABSOLUTE'">(Total Cost/Style)</span>
                                            </div>
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let allocatedSupplier of costingAllocation.allocatedSuppliers;index as astli">
                                    <td >
                                        <div>
                                            
                                            <div class="d-flex justify-content-around">
                                                {{allocatedSupplier.supplierName}} 
                                                <span (click)="changeBlankSupplierCurrency(allocatedSupplier,'USD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="allocatedSupplier.currency==='CAD'">
                                                    <img src="../../../../../assets/cad.png" width="20" height="auto" ></span>
                                                <span (click)="changeBlankSupplierCurrency(allocatedSupplier,'CAD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="allocatedSupplier.currency==='USD'">
                                                    <img src="../../../../../assets/usd.png" width="20" height="auto"></span>
                                                <span (click)="changeBlankSupplierCurrency(allocatedSupplier,'CAD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="!allocatedSupplier.currency || allocatedSupplier.currency===null">
                                                    <img src="../../../../../assets/deleteImage2.png" width="20" height="auto"></span>
                                                
                                            </div>
                                            
                                        </div>
                                    </td>
                                    <ng-container *ngFor="let asctd of  allocatedSupplier.costTypeDetails;index as asctdi">
                                        <ng-container *ngIf="asctd.source==='USER_INPUT'">
                                            <td class="p-0">
                                                <input name="asctd_{{astli}}_{{asctdi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #asctdVar="ngModel" required 
                                                [disabled]="!editable"
                                                            [(ngModel)]="asctd.uiCost" [ngClass]="{'is-invalid':asctdVar.touched && asctdVar.errors}" [customMin]="0" (change)="calculateAllocationSheet(costingAllocation)">
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                    
                                </tr>
                            </tbody>
                        </table>

                <div class="row">
                    <div class="col-sm-6" *ngIf="costingAllocation.allocatedPrinters && costingAllocation.allocatedPrinters.length>0">
                        
                        <table class="table table-sm f-10 table-bordered" style="width: 100%;" *ngIf="costingAllocation.allocatedPrinters && costingAllocation.allocatedPrinters.length>0">
                            <thead >
                                <tr style="background: lightblue;" style="background: lightblue;">
                                    <th style="width: 10%;" class="text-center">&nbsp;</th>
                                    <ng-container *ngFor="let allocatedPrinter of costingAllocation.allocatedPrinters;index as aptli">
                                        <th class="text-center">
                                            <div class="">
                                                <div>
                                                    {{allocatedPrinter.printerName}}
                                                    </div>
                                            </div>
                                        </th>
                                    </ng-container>
                                    
                                </tr>
                                <tr *ngIf="editable">
                                    <th style="width: 10%;" class="text-right align-middle">Full Package Toggle For All Styles</th>
                                    <ng-container *ngFor="let allocatedPrinter of costingAllocation.allocatedPrinters;index as aptli">
                                        <th class="text-center">
                                            <div class="">
                                                <button type="button" (click)="toggleFullPackageForAllStyles(allocatedPrinter,costingAllocation,true)" class="btn btn-sm f-8 btn-outline-warning">All&nbsp;</button>
                                                <button type="button" (click)="toggleFullPackageForAllStyles(allocatedPrinter,costingAllocation,false)" class="btn btn-sm ml-2 f-8 btn-outline-danger">None</button>
                                               
                                            </div>
                                        </th>
                                    </ng-container>
                                    
                                </tr>
                                <tr>
                                    <th style="width: 10%;" class="text-right align-middle">Printer Ship Date</th>
                                    <ng-container *ngFor="let allocatedPrinter of costingAllocation.allocatedPrinters;index as aptli">
                                        <th class="text-center">
                                            <div class="f-10">
                                                <div>
                                                    <!--<label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Ship Date</label>-->
                                                        <div class="d-flex d-inline">
                                                            <input matInput [matDatepicker]="shipDatePicker" id="shipDate_{{aptli}}" name="shipDate_{{aptli}}" [(ngModel)]="allocatedPrinter.shipDate" style="cursor: pointer;font-size: 10px;"
                                                             placeholder="Ship Date"   class="form-control  form-control-sm f-10" required #shipDateVar="ngModel" [ngClass]="{'is-invalid':shipDateVar && shipDateVar.errors}" [disabled]="!editable"  
                                                             (click)="shipDatePicker.open()" [max]="salesOrder.orderCancelDate">
                                                            <mat-datepicker #shipDatePicker></mat-datepicker>
                                                        </div>
                                                </div>
                                            </div>
                                        </th>
                                    </ng-container>
                                    
                                </tr>
                                <tr>
                                    <th style="width: 10%;" class="text-right align-middle">Printer Cancel Date</th>
                                    <ng-container *ngFor="let allocatedPrinter of costingAllocation.allocatedPrinters;index as aptli">
                                        <th class="text-center">
                                            <div class="f-10">
                                                <div>
                                                    <!--<label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Ship Date</label>-->
                                                        <div class="d-flex d-inline">
                                                            <input matInput [matDatepicker]="cancelDatePicker" id="cancelDate_{{aptli}}" name="cancelDate_{{aptli}}" [(ngModel)]="allocatedPrinter.cancelDate" style="cursor: pointer;font-size: 10px;"
                                                            placeholder="Cancel Date"  class="form-control  form-control-sm f-10" required #cancelDateVar="ngModel" [ngClass]="{'is-invalid':cancelDateVar && cancelDateVar.errors}" [disabled]="!editable"  
                                                            (click)="cancelDatePicker.open()" [max]="salesOrder.orderCancelDate">
                                                            <mat-datepicker #cancelDatePicker></mat-datepicker>
                                                        </div>
                                                </div>
                                            </div>
                                        </th>
                                    </ng-container>
                                    
                                </tr>
                                <tr>
                                    <th style="width: 10%;" class="text-right align-middle">Printer Cost/Currency</th>
                                    <ng-container *ngFor="let allocatedPrinter of costingAllocation.allocatedPrinters;index as aptli">
                                        <th class="text-center">
                                            <div class="">
                                                <span (click)="changePrinterCurrency(allocatedPrinter,'USD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="allocatedPrinter.currency==='CAD'">
                                                    <img src="../../../../../assets/cad.png" width="20" height="auto" ></span>
                                                <span (click)="changePrinterCurrency(allocatedPrinter,'CAD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="allocatedPrinter.currency==='USD'">
                                                    <img src="../../../../../assets/usd.png" width="20" height="auto"></span>
                                                <span (click)="changePrinterCurrency(allocatedPrinter,'CAD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="!allocatedPrinter.currency || allocatedPrinter.currency===null">
                                                    <img src="../../../../../assets/deleteImage2.png" width="20" height="auto"></span>
                                            </div>
                                        </th>
                                    </ng-container>
                                    
                                </tr>
                                
                            </thead>
                            <tbody>
                                <tr *ngFor="let ctd of  costingAllocation.allocatedPrinters[0].costTypeDetails;index as ctdi">
                                    <ng-container *ngIf="ctd.source==='USER_INPUT'">
                                        <td style="width: 60%;" class="text-right">{{ctd.description}}
                                            
                                                <span *ngIf="ctd.calculationType==='PERCENTAGE'">(%)</span>
                                                <span *ngIf="ctd.calculationType==='ABSOLUTE_UNIT'">($ / EA)</span>
                                                <span *ngIf="ctd.calculationType==='ABSOLUTE'">(Total Cost/Style)</span>
                                            
                                        </td>
                                        <ng-container *ngFor="let allocatedPrinter of costingAllocation.allocatedPrinters;index as aptli">
                                            <ng-container *ngFor="let apctd of  allocatedPrinter.costTypeDetails;index as apctdi">
                                                <ng-container *ngIf="apctd.description===ctd.description">
                                                    <td class="p-0">
                                                        <input name="apctd{{aptli}}{{ctdi}}" id="apctd{{aptli}}{{ctdi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;"
                                                         #apctdVar="ngModel" required 
                                                        [disabled]="!editable"
                                                        [(ngModel)]="apctd.uiCost" [ngClass]="{'is-invalid':apctdVar.touched && apctdVar.errors}" [customMin]="0"
                                                        (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(apctdVar.name,'apctd'+aptli,'DOWN')" 
                                                        (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(apctdVar.name,'apctd'+aptli,'UP')"
                                                        (change)="calculateAllocationSheet(costingAllocation)">

                                                    </td>
                                                </ng-container>
                                            </ng-container>
                                            
                                        </ng-container>
                                    </ng-container>
                                    
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="col-sm-6">
                        <!--<button *ngIf="userAccess?.isSupportAdmin" (click)="audit(costingAllocationForm)">Audit Form</button>-->
                        <table class="table table-sm f-10 " >
                            <tbody >
                                <tr>
                                    <th class="text-center" style="width: 30%;" style="background: lightblue;">USD to CAD</th>
                                    <td class="p-0 " style="border-top: 0px;">
                                        <input input type="number" min="0.0001" max="9999" name="exchangeRateUsdToCad" class="form-control cell-min-nowrap form-control-sm f-11"
                                        style="align-self: center;text-align: right; font-size:11px; width:50px;" #exchangeRateUsdToCad="ngModel" required [disabled]="!editable"
                                        [(ngModel)]="costingAllocation.exchangeRateUsdToCad" (change)="calculateAllocationSheet(costingAllocation)">
                                    </td>
                                    <th class="text-center" style="width: 30%;" style="background: lightblue;">CAD to USD</th>
                                    <td class="p-0" style="border-top: 0px;">
                                        <input input type="number" min="0.0001" max="9999" name="exchangeRateCadToUsd" class="form-control cell-min-nowrap form-control-sm f-11"
                                        style="align-self: center;text-align: right; font-size:11px; width:50px;" #exchangeRateCadToUsd="ngModel" required [disabled]="!editable"
                                        [(ngModel)]="costingAllocation.exchangeRateCadToUsd" (change)="calculateAllocationSheet(costingAllocation)">
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                        
                        <table class="table table-sm f-10 table-bordered" >
                            <thead style="background: lightblue;">
                                <tr>
                                    <th style="width: 30%;">Organization</th>
                                    <ng-container *ngFor="let octd of  costingAllocation.orgCostTypeDetails">
                                        <th *ngIf="octd.source==='USER_INPUT'">{{octd.description}}
                                           
                                               <span *ngIf="octd.calculationType==='PERCENTAGE'">(%)</span>
                                               <span *ngIf="octd.calculationType==='ABSOLUTE_UNIT'">($ / EA)</span>
                                               <span *ngIf="octd.calculationType==='ABSOLUTE'">(Total Cost)</span>
                                           
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-start">
                                            <div class="d-flex justify-content-around">
                                                <!--
                                                <span (click)="costingAllocation.orderCurrency='USD';changeCostTypeCurrency(costingAllocation.orgCostTypeDetails,'USD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="costingAllocation.orderCurrency==='CAD'">
                                                    <img src="../../../../../assets/cad.png" width="20" height="auto" ></span>
                                                <span (click)="costingAllocation.orderCurrency='CAD';changeCostTypeCurrency(costingAllocation.orgCostTypeDetails,'CAD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="costingAllocation.orderCurrency==='USD'">
                                                    <img src="../../../../../assets/usd.png" width="20" height="auto"></span>
                                                -->
                                            <span class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="costingAllocation.orderCurrency==='CAD'">
                                                <img src="../../../../../assets/cad.png" width="20" height="auto" >&nbsp; Philcos</span>
                                            <span  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="costingAllocation.orderCurrency==='USD'">
                                                <img src="../../../../../assets/usd.png" width="20" height="auto">&nbsp; Philcos</span>
                                                    
                                                
                                            </div>
                                        </div>
                                    </td>
                                    <ng-container *ngFor="let octd of  costingAllocation.orgCostTypeDetails;index as octdi">
                                        <ng-container *ngIf="octd.source==='USER_INPUT'">
                                            <td class="p-0">
                                                <input name="octd_{{octdi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #octdVar="ngModel" required 
                                                [disabled]="!editable"
                                                            [(ngModel)]="octd.uiCost" [ngClass]="{'is-invalid':octdVar.touched && octdVar.errors}" [customMin]="0"
                                                            (change)="calculateAllocationSheet(costingAllocation)">
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                    
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-sm f-10 table-bordered" style="width: 100%;">
                            <thead style="background: lightblue;">
                                <tr>
                                    <th style="width: 30%;">Customer</th>
                                    <ng-container *ngFor="let ctd of  costingAllocation.customerCostTypeDetails">
                                        <th *ngIf="ctd.source==='USER_INPUT'">{{ctd.description}}
                                            
                                                <span *ngIf="ctd.calculationType==='PERCENTAGE'">(%)</span>
                                                <span *ngIf="ctd.calculationType==='ABSOLUTE_UNIT'">($ / EA)</span>
                                                <span *ngIf="ctd.calculationType==='ABSOLUTE'">(Total Cost)</span>
                                            
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-start">
                                            <div class="d-flex justify-content-around">
                                                
                                                <!--
                                                <span (click)="costingAllocation.orderCurrency='USD';changeCostTypeCurrency(costingAllocation.customerCostTypeDetails,'USD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="costingAllocation.orderCurrency==='CAD'">
                                                    <img src="../../../../../assets/cad.png" width="20" height="auto" ></span>
                                                <span (click)="costingAllocation.orderCurrency='CAD';changeCostTypeCurrency(costingAllocation.customerCostTypeDetails,'CAD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="costingAllocation.orderCurrency==='USD'">
                                                    <img src="../../../../../assets/usd.png" width="20" height="auto"></span>
                                                -->
                                                    <span class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="costingAllocation.orderCurrency==='CAD'">
                                                        <img src="../../../../../assets/cad.png" width="20" height="auto" >&nbsp; {{salesOrder.customerName}} </span>
                                                    <span  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="costingAllocation.orderCurrency==='USD'">
                                                        <img src="../../../../../assets/usd.png" width="20" height="auto">&nbsp; {{salesOrder.customerName}} </span>
                                                
                                            </div>
                                        </div>
                                    </td>
                                    <ng-container *ngFor="let cctd of  costingAllocation.customerCostTypeDetails;index as cctdi">
                                        <ng-container *ngIf="cctd.source==='USER_INPUT'">
                                            <td class="p-0">
                                                <input name="cctd_{{cctdi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #cctdVar="ngModel" required 
                                                [disabled]="!editable"
                                                            [(ngModel)]="cctd.uiCost" [ngClass]="{'is-invalid':cctdVar.touched && cctdVar.errors}" [customMin]="0"
                                                            (change)="calculateAllocationSheet(costingAllocation)">
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                    
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-sm f-10 table-bordered" 
                            *ngIf="costingAllocation.allocatedSuppliers && costingAllocation.allocatedSuppliers.length>0
                                && costingAllocation.allocatedPrinters && costingAllocation.allocatedPrinters.length>0"><!-- [style]="'width: '+(10*costingAllocation.allocatedPrinters.length)+'%;'"-->
                            <thead style="background: lightblue;">
                                <tr>
                                    <th style="width: 30%;">Freight Details</th>
                                    <ng-container *ngIf="costingAllocation.allocatedSuppliers[0].freightDetailsMatrix && costingAllocation.allocatedSuppliers[0].freightDetailsMatrix.length>0">
                                        <ng-container *ngFor="let fd of  costingAllocation.allocatedSuppliers[0].freightDetailsMatrix">
                                            <th>{{fd.printerName}}
                                                
                                                    <!--<span *ngIf="ctd.calculationType==='PERCENTAGE'">(%)</span>-->
                                                    <span>($ / EA)</span>
                                                    <!--<span *ngIf="ctd.calculationType==='ABSOLUTE'">(Total Cost)</span>-->
                                                
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let allocatedSupplier of costingAllocation.allocatedSuppliers;index as asfdi">
                                    <td >
                                        <div class="d-flex align-items-start">
                                            
                                            <div class="d-flex justify-content-around">
                                                
                                                <span (click)="changeBlankSupplierCurrency(allocatedSupplier,'USD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="allocatedSupplier.currency==='CAD'">
                                                    <img src="../../../../../assets/cad.png" width="20" height="auto" > &nbsp;{{allocatedSupplier.supplierName}} </span>
                                                <span (click)="changeBlankSupplierCurrency(allocatedSupplier,'CAD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="allocatedSupplier.currency==='USD'">
                                                    <img src="../../../../../assets/usd.png" width="20" height="auto"> &nbsp;{{allocatedSupplier.supplierName}} </span>
                                                <span (click)="changeBlankSupplierCurrency(allocatedSupplier,'CAD')"  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="!allocatedSupplier.currency || allocatedSupplier.currency===null">
                                                    <img src="../../../../../assets/deleteImage2.png" width="20" height="auto"></span>
                                                    
                                                
                                            </div>
                                            
                                        </div>
                                    </td>
                                    <ng-container *ngFor="let asfdc of  allocatedSupplier.freightDetailsMatrix;index as asfdci">
                                        <ng-container >
                                            <td class="p-0">
                                                <input name="asfdc{{asfdci}}{{asfdi}}" id="asfdc{{asfdci}}{{asfdi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #asfdcVar="ngModel" required 
                                                [disabled]="!editable"
                                                [(ngModel)]="asfdc.value" [ngClass]="{'is-invalid':asfdcVar.touched && asfdcVar.errors}" [customMin]="0"
                                                (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(asfdcVar.name,'asfdc'+asfdci,'DOWN')" 
                                                (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(asfdcVar.name,'asfdc'+asfdci,'UP')">
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                    
                                </tr>
                            </tbody>
                        </table>


                    
                
                        <!--<table class="table table-sm f-10 table-bordered" style="width: 50%;" *ngIf="costingAllocation.allocatedSuppliers && costingAllocation.allocatedSuppliers.length>0">
                            <thead style="background: lightblue;">
                                <tr>
                                    <th>Blank Suppliers Allocated</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let allocatedSupplier of costingAllocation.allocatedSuppliers">
                                    <td>
                                        <div >
                                            {{allocatedSupplier.supplierName}}
                                        </div>
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>-->
                
                
                    </div>
                    </div><!-- Top Section Ends-->

                
                    <!--<button class="btn btn-sm btn-primary ml-3" (click)="addPrinterCombination()">+ Printer</button>-->
                    
                    <ng-container *ngFor="let styleCosting of costingAllocation.styleCosting;index as sci">
                        <!--<ng-container *ngIf="salesOrder && getOrderDetailById(styleCosting.orderDetailId)">
                            <app-order-detail [salesOrder]="salesOrder" [orderDetail]="getOrderDetailById(styleCosting.orderDetailId)" [editable]="false" [createAction]="false" [orderDetailIndex]="sci" [colspanIncrementCount]="1"
                            [showSubOrderDetailPoCustomer]="true" [collapseOnInit]="true"></app-order-detail>
                        </ng-container>-->
                        <div class="mx-2 d-flex justify-content-end" *ngIf="styleCosting.status!=='CANCELLED'">
                            <span class="btn btn-outline-info ml-3 f-10" style="border:none;cursor: pointer;" (click)="loadPrinterSelectionDialog(styleCosting)" *ngIf="editable">+ Printer Combination</span>
                            <span class="btn btn-outline-info ml-3 ml-3 f-10" style="border:none;cursor: pointer;" (click)="loadBlankSupplierSelectionDialog(styleCosting)" *ngIf="editable">+ Supplier Combination</span>
                        </div>    
                <div class="table-responsive mid-height-hor-scroll light-scrollbar mb-2">
                    <table class="table table-sm f-10 table-bordered" ><!--[style]="'width: '+(25+ (styleCosting.printerCount * 10)+(styleCosting.supplierCount *30)) +'%;'"-->
                        <thead>
                            <tr>
                                <th colspan="5" rowspan="2" style="text-align: center;position: relative;">
                                    <div>
                                        <div style="cursor: pointer;left: 4px;top:4px;z-index: 2;"  class="hover-bg-higlight" >
                                            <div class="d-flex flex-column">
                                            <span class="f-10 badge badge-pill color-0 mb-1" style="width: 50% ;font-weight: 500!important;" *ngIf="styleCosting.repeatOrder && userAccess?.canViewRepeatOrderDetails" (click)="showRepeatOrderDetails(salesOrder,styleCosting)" >Repeat Order</span> 
                                            <!--<span class="f-10 badge badge-pill color-1 mb-1" style="width: 50% ;font-weight: 500!important;" *ngIf="userAccess?.isSupportAdmin" (click)="showRepeatOrderDetails(salesOrder,styleCosting)" >Search Repeat Order</span> -->
                                            <span class="f-10 badge badge-pill color-3 mb-1" style="width: 50% ;font-weight: 500!important;z-index: 1;" *ngIf="styleCosting.internalNotes && styleCosting.internalNotes.length>0" [matTooltip]="styleCosting.internalNotes" matTooltipPosition="right" >Special Notes</span> 
                                            </div>
                                        </div>
                                        <div  style="position: absolute;cursor: pointer;right: 4px;top:4px;">
                                            <span>
                                                <div class="d-flex justify-content-center">
                                                    <a target="_blank" [routerLink]="['/costingsheet/'+salesOrderId+'/'+styleCosting.orderDetailId]" class="card-link f-11">View Costing Sheet</a>
                                                    </div>
                                            </span>

                                        </div>
                                            

                                        
                                        <span>
                                            <div class="">
                                            <span class="" style="font-size: 20px;" *ngIf="styleCosting.sku.length&lt;10">{{styleCosting.sku}}</span><!-- [{{styleCosting.status}}]-->
                                            <span class="" style="font-size: 12px;" *ngIf="styleCosting.sku.length>10">{{styleCosting.sku}}</span><!-- [{{styleCosting.status}}]-->
                                            <!--<a  target="_blank" [routerLink]="['/costingsheet/'+salesOrderId+'/'+styleCosting.orderDetailId]">                       
                                                
                                                </a> -->
                                                <span style="cursor: pointer; " class="ml-1" (click)="openOrderDetailModal(styleCosting, sci)"> <!--vertical-align: middle; -->
                                                   <i class="fas fa-info-circle fa-2x" aria-hidden="true" ></i>
                                                </span>
                                                
                                            </div>
                                        </span>
                                        <div class="row" *ngFor="let ctd of styleCosting.costTypeDetails; index as ctdi">
                                            <div class="col-sm-9 d-flex justify-content-end align-items-center">{{ctd.description}} 
                                                <div>
                                                    <span *ngIf="ctd.calculationType==='PERCENTAGE'" class="text-muted">(%)</span>
                                                    <span *ngIf="ctd.calculationType==='ABSOLUTE_UNIT'" class="text-muted">($ / EA)</span>
                                                    <span *ngIf="ctd.calculationType==='ABSOLUTE'" class="text-muted">(Total Cost/Style)</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="d-flex align-items-center">
                                                    <input name="ctd_{{sci}}_{{ctdi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #ctdVar="ngModel" required 
                                                    [disabled]="!editable"
                                                        [(ngModel)]="ctd.uiCost" [ngClass]="{'is-invalid':ctdVar.touched && ctdVar.errors}" [customMin]="0"
                                                        (change)="calculateAllocationSheet(costingAllocation)">    
                                                    <span *ngIf="ctd.description==='Royalty' && ctd.uiCost!==ctd.defaultCost" class="pl-1" ><i class="fas fa-exclamation-circle text-danger"></i></span>
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <ng-container
                                    *ngIf="styleCosting.printerCombDetails && styleCosting.printerCombDetails.length>0">
                                    <th [style.background]="printerCombo.allocated?(printerCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(239,255,127,'+((api+1)*0.25)+')'):'lightgray'"
                                    style="vertical-align: top !important;"
                                    [attr.colspan]="printerCombo.printerDetails.length" 
                                     *ngFor="let printerCombo of styleCosting.printerCombDetails; index as api"> <!--[attr.rowspan]="printerCombo.printerDetails.length===1?'2':'1'"-->
                                       <div class="d-flex">
                                            <div class="custom-control custom-checkbox mr-sm-2">
                                                <input type="checkbox" class="custom-control-input " name="ap_include_{{sci}}_{{api}}" id="ap_include_{{sci}}_{{api}}" 
                                                (change)="onPrinterStyleInclusion($event,printerCombo)"
                                                [checked]="printerCombo.allocated" [disabled]="!editable">
                                                <label class="custom-control-label" for="ap_include_{{sci}}_{{api}}">{{printerCombo.combinationName}}</label>
                                            </div>
                                            <div *ngIf="editable && styleCosting.status!=='CANCELLED'">
                                                <span (click)="removePrinterCombination(styleCosting,printerCombo)"><i class="fas fa-trash text-danger shadow"></i></span>
                                            </div>
                                            <!--<div>
                                                <div >
                                                    <span type="button" id="dropdownMenu2" class="btn f-9 btn-sm" style="border:none;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fas fa-list "></i>
                                                    </span>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                      <button class="dropdown-item f-10" type="button">Action</button>
                                                      <button class="dropdown-item f-10" type="button">Another action</button>
                                                      <button class="dropdown-item f-10" type="button" *ngIf="editable && styleCosting.status!=='CANCELLED'" (click)="removePrinterCombination(styleCosting,printerCombo)">
                                                        <i class="fas fa-trash text-danger shadow"></i> Delete Combination
                                                    </button>
                                                    </div>
                                                  </div>
                                            </div>-->
                                        </div>
                                        <div *ngIf="editable || printerCombo.additionalNotes">
                                            <textarea class="form-control p-0" cols="50" rows="2" style="font-size:9px;" placeholder="Composition / Additional Notes.." name="composition{{sci}}_{{api}}" #compositionVar="ngModel" [disabled]="!editable"
                                  [(ngModel)]="printerCombo.additionalNotes"></textarea>
                                        </div>
                                        <div *ngIf="printerCombo.allocated && printerCombo.printerDetails.length>1">
                                            <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Intial Blank Delivery to</label>
                                            <select class="form-control form-control-sm p-0" required style="font-size: 9px !important;" name="ibd{{sci}}_{{api}}" 
                                                [(ngModel)]="printerCombo.intialDeliveryPrinterId" [disabled]="!editable"
                                                #initialDeliveryVar="ngModel" (change)="initalDeliveryChanged(printerCombo)"
                                                [ngClass]="{'is-invalid':initialDeliveryVar && initialDeliveryVar.errors}">
                                                    <option class="f9" [value]="printer.printerId" *ngFor="let printer of printerCombo.printerDetails">{{printer.printerName}}</option>
                                                </select>
                                        </div>
                                        <div *ngIf="printerCombo.allocated">
                                            <button class="btn btn-sm btn-outline-info f-9" type="button" style="border:none;" *ngIf="editable && styleCosting.status!=='CANCELLED'" (click)="addPrintertoCombinationDialog(styleCosting,printerCombo)">+ Printer</button>
                                        </div>
                                        <div *ngIf="printerCombo.allocated"> Avg. Cost: {{printerCombo.averagePrintCost}}</div>
                                        
                    
                                    </th>
                                </ng-container>
                
                                <ng-container
                                    *ngIf="styleCosting.bSupplierCombDetails && styleCosting.bSupplierCombDetails.length>0">
                                    <th [attr.colspan]="bSupplierCombo.bSupplierDetails.length*4" [attr.rowspan]="bSupplierCombo.bSupplierDetails.length===1?'2':'1'" 
                                    style="vertical-align: top !important;" [style.background]="bSupplierCombo.allocated?(bSupplierCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(135,206,250,'+((abi+1)*0.25)+')'):'lightgray'" 
                                    *ngFor="let bSupplierCombo of styleCosting.bSupplierCombDetails;index as abi">
                                        <div class="d-flex">
                                            <div class="custom-control custom-checkbox mr-sm-2">
                                                <input type="checkbox" class="custom-control-input " name="ab_include_{{sci}}_{{abi}}" id="ab_include_{{sci}}_{{abi}}" 
                                                (change)="onBlankSupplierStyleInclusion($event,bSupplierCombo)"
                                                [checked]="bSupplierCombo.allocated" [disabled]="!editable">
                                                <label class="custom-control-label" for="ab_include_{{sci}}_{{abi}}">{{bSupplierCombo.combinationName}}</label>
                                            </div>
                                            <div *ngIf="editable && styleCosting.status!=='CANCELLED'">
                                                <span (click)="removeBlankSupplierCombination(styleCosting,bSupplierCombo)"><i class="fas fa-trash text-danger shadow"></i></span>
                                            </div>
                                        </div>
                                         <div *ngIf="bSupplierCombo.allocated">
                                             <button class="btn btn-sm btn-outline-info f-9" type="button" style="border:none;" *ngIf="editable && styleCosting.status!=='CANCELLED'" (click)="addBlankSuppliertoCombinationDialog(styleCosting,bSupplierCombo)">+ Suppliers</button>
                                         </div>
                                         <div *ngIf="bSupplierCombo.allocated"> Avg. Cost: {{bSupplierCombo.averageBlankCost}}</div>
                                    </th>
                                </ng-container>
                
                            </tr>
                            <tr>
                                <ng-container *ngIf="styleCosting.printerCombDetails && styleCosting.printerCombDetails.length>0">
                                    <ng-container *ngFor="let printerCombo of styleCosting.printerCombDetails; index as api">
                                        <ng-container  *ngIf="printerCombo.printerDetails.length>1">
                                            <th *ngFor="let printer of printerCombo.printerDetails;index as apdi" [style.background]="printerCombo.allocated?(printerCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(239,255,127,'+((api+1)*0.25)+')'):'lightgray'">
                                                <!--<div class="form-check form-check-inline">
                                                   {{printer.printerName}}
                                                </div>-->
                                                <div class="d-flex">
                                                    <span class="pr-2"> {{printer.printerName}}</span>
                                                    <div *ngIf="editable && styleCosting.status!=='CANCELLED'">
                                                        <span (click)="removePrinterFromCombination(printerCombo,printer)"><i class="fas fa-trash text-danger shadow"></i></span>
                                                    </div>
                                                </div>
                                            </th>
                                        </ng-container>
                                        <ng-container *ngIf="printerCombo.printerDetails.length===1">
                                            <th [style.background]="printerCombo.allocated?(printerCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(239,255,127,'+((api+1)*0.25)+')'):'lightgray'">
                                                <div class="custom-control custom-checkbox-primary mr-sm-2" >
                                                    <input type="checkbox" class="custom-control-input " name="ap_isfp_{{sci}}_{{api}}" id="ap_isfp_{{sci}}_{{api}}" 
                                                    (change)="onPrinterFullPackageSelection($event,styleCosting,printerCombo)"
                                                    [checked]="printerCombo.fullPackage" [disabled]="!editable">
                                                    <label class="custom-control-label f-9" for="ap_isfp_{{sci}}_{{api}}">Full Package</label>
                                                </div>
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                
                                <ng-container *ngIf="styleCosting.bSupplierCombDetails && styleCosting.bSupplierCombDetails.length>0">
                                    <ng-container *ngFor="let bSupplierCombo of styleCosting.bSupplierCombDetails;index as abi">
                                        <ng-container  *ngIf="bSupplierCombo.bSupplierDetails.length>1">
                                            <th colspan="4" *ngFor="let bSupplier of bSupplierCombo.bSupplierDetails;index as absdi"  [style.background]="bSupplierCombo.allocated?(bSupplierCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(135,206,250,'+((abi+1)*0.25)+')'):'lightgray'" >
                                                <div class="d-flex">
                                                    <span class="pr-2">{{bSupplier.supplierName}}</span>
                                                    <!--<div *ngIf="styleCosting.status==='CANCELLED' && bSupplierCombo.approvalStatus==='APPROVED'">
                                                        <select class="form-control form-control-sm p-0" required style="font-size: 9px !important;" name="blanksStatus{{abi}}{{absdi}}{{sci}}" 
                                                            [(ngModel)]="bSupplier.blankStatus" [disabled]="!editable"
                                                            #blanksStatusVar="ngModel" >
                                                            <option class="f9" value="Inventory at Printer">Inventory at Printer</option>
                                                            <option  class="f9" value="Discard Allocation">Discard Allocation</option>
                                                        </select>
                                                    </div>-->
                                                    
                                                    <div *ngIf="editable">
                                                        <span (click)="removeBlankSupplierFromCombination(bSupplierCombo,bSupplier)"><i class="fas fa-trash text-danger shadow"></i></span>
                                                    </div>
                                                </div>
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                
                            </tr>
                            <tr>
                                <th style="width: 10%;">
                                    <!-- <div class="mb-1" style="text-align: center;" *ngIf="styleCosting?.preProSampleApprovedPrinter">
                                       <span class="badge badge-success f-12">
                                          <i class="fas fa-tshirt" aria-hidden="true"></i>&nbsp;&nbsp;{{styleCosting?.preProSampleApprovedPrinter}}</span>
                                    </div> -->
                                    Style
                                 </th>
                                <th style="width: 4%;">Order Size</th>
                                <th style="width: 4%;">Order + Sample Qty</th>
                                <th style="width: 5%;">Extra Blanks
                                    <div class="d-flex">
                                    
                                    <div *ngIf="styleCosting.extraQtyType==='Percentage'">
                                        <div class="d-flex align-items-center">
                                            <input name="eqper{{sci}}" id="eqper{{sci}}" type="number" 
                                            class="form-control form-control-sm cell-min-nowrap" 
                                            style="font-size: 11px;" #eqperVar="ngModel" required [disabled]="!editable" [customMin]="0"
                                            [(ngModel)]="styleCosting.extraQtyPercentage" [ngClass]="{'is-invalid':eqperVar.touched && eqperVar.errors}" (change)="calculateExtraQty(styleCosting,eqperVar.value)" 
                                            > 
                                            <div> &nbsp;%</div>
                                        </div>
                                    </div>
                                    <div>
                                        <select class="form-control form-control-sm p-0" required style="font-size: 9px !important;" name="extraQtyType_{{sci}}" 
                                        [(ngModel)]="styleCosting.extraQtyType" [disabled]="!editable"
                                        #eqTypeVar="ngModel">
                                        <option class="f9" value="Percentage">%</option>
                                        <option  class="f9" value="Manual">Manual</option>
                                        </select>
                                    </div>
                                    </div>
                                </th>
                                <th style="width: 4%;">Blanks Qty</th>
                                <ng-container *ngIf="styleCosting.printerCombDetails && styleCosting.printerCombDetails.length>0">
                                    <ng-container *ngFor="let printerCombo of styleCosting.printerCombDetails; index as api">
                                        <ng-container *ngFor="let printer of printerCombo.printerDetails; index as apip">
                                            <th class="cell-min-nowrap" style="width: 4%;" *ngIf="printerCombo.allocated && printerCombo.printerDetails.length===1" [style.background]="printerCombo.allocated?(printerCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(239,255,127,'+((api+1)*0.25)+')'):'lightgray'" >
                                                
                                                <div >
                                                    Printing Rate/Unit
                                                    <span class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="printer.currency==='CAD'">
                                                        <img src="../../../../../assets/cad.png" width="20" height="auto" ></span><!--(click)="changeCurrency(printer,'currency','USD');changeCostTypeCurrency(printer.costTypeDetails,'USD')"  -->
                                                    <span class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="printer.currency==='USD'">
                                                        <img src="../../../../../assets/usd.png" width="20" height="auto"></span>
                                                </div>
                                            </th>
                                            <th class="p-0" style="width: 4%;" *ngIf="!printerCombo.allocated">
                                                &nbsp;
                                            </th>
                                            <th class="cell-min-nowrap p-0" style="width: 6%;" *ngIf="printerCombo.allocated && printerCombo.printerDetails.length>1" [style.background]="printerCombo.allocated?(printerCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(239,255,127,'+((api+1)*0.25)+')'):'lightgray'" >
                                                <select class="form-control form-control-sm p-0" required style="font-size: 9px !important;" name="jobType{{api}}{{apip}}{{sci}}" 
                                                [(ngModel)]="printer.jobType" [disabled]="!editable"
                                                #jobTypeVar="ngModel" (change)="jobTypeChanged(printer, printerCombo)"
                                                [ngClass]="{'is-invalid':jobTypeVar && jobTypeVar.errors}">
                                                    <option class="f9" [value]="jt" *ngFor="let jt of printerJobTypeFromMetadata">{{jt}}</option>
                                                </select>
                                                
                                                <div class="px-1">
                                                    Rate/Unit
                                                    <span  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="printer.currency==='CAD'">
                                                        <img src="../../../../../assets/cad.png" width="20" height="auto" ></span>
                                                    <span  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="printer.currency==='USD'">
                                                        <img src="../../../../../assets/usd.png" width="20" height="auto"></span>
                                                </div>
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>   
                                <ng-container *ngIf="styleCosting.bSupplierCombDetails && styleCosting.bSupplierCombDetails.length>0">
                                    <ng-container *ngFor="let bSupplierCombo of styleCosting.bSupplierCombDetails; index as abi">
                                        <ng-container *ngFor="let bSupplier of bSupplierCombo.bSupplierDetails;index as absdi">
                                            <th class="cell-min-nowrap" style="width: 4%;"  [style.background]="bSupplierCombo.allocated?(bSupplierCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(135,206,250,'+((abi+1)*0.25)+')'):'lightgray'">Blanks Qty</th>
                                            <th class="cell-min-nowrap" style="width: 4%;"  [style.background]="bSupplierCombo.allocated?(bSupplierCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(135,206,250,'+((abi+1)*0.25)+')'):'lightgray'">
                                                <div >
                                                    Blanks <BR> Rate/Unit
                                                    <span  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="bSupplier.currency==='CAD'">
                                                        <img src="../../../../../assets/cad.png" width="20" height="auto" ></span>
                                                    <span  class="px-1 py-0 f-10" style="border-radius: 0px;cursor: pointer;" *ngIf="bSupplier.currency==='USD'">
                                                        <img src="../../../../../assets/usd.png" width="20" height="auto"></span>
                                                </div>
                                            </th>
                                            <th class="cell-min-nowrap" style="min-width: 150px;"  [style.background]="bSupplierCombo.allocated?(bSupplierCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(135,206,250,'+((abi+1)*0.25)+')'):'lightgray'">
                                                
                                                    <div >
                                                        <label class="mb-0 " style="font-size: .60rem; color: lightslategray;">COO</label>
                                                        <!--<div style="width: 50%;">
                                                            <input name="coo{{sci}}{{abi}}{{absdi}}" type="text" class="form-control form-control-sm " style="font-size: 10px;" #cooVar="ngModel" required 
                                                            [disabled]="!editable" minlength="1" placeholder="COO"
                                                                    [(ngModel)]="bSupplier.coo" [ngClass]="{'is-invalid':cooVar.touched && cooVar.errors}">
                                                        </div>-->
                                                        <div style="width: 50%;">
                                                        <select  style="width: 50%;" class="form-control form-control-sm p-0" required style="font-size: 10px !important;" name="coo{{sci}}{{abi}}{{absdi}}" 
                                                            [(ngModel)]="bSupplier.coo" [disabled]="!editable"
                                                            #cooVar="ngModel" [ngClass]="{'is-invalid':cooVar && cooVar.errors}">
                                                                <option class="f9" [value]="coo" *ngFor="let coo of cooListFromMetadata">{{coo}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    Blanks Code
                                            </th>
                                            <th class="cell-min-nowrap" style="width: 4%;"  [style.background]="bSupplierCombo.allocated?(bSupplierCombo.approvalStatus==='APPROVED'?'aquamarine':'rgba(135,206,250,'+((abi+1)*0.25)+')'):'lightgray'">Blank Size</th>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                
                
                            </tr>
                        </thead>
                        <tbody>
                            
                               
                                <tr *ngFor="let blankSizeQty of styleCosting.blankSizeQty; index as bsqi" class="">
                                    <td [attr.rowspan]="styleCosting.blankSizeQty.length" *ngIf="bsqi===0">
                                        <div class="col-auto">
                                            <div style="position: relative;">
                                             <div class="mb-1" style="text-align: center;" *ngIf="styleCosting?.preProSampleApprovedPrinter">
                                                <span class="badge badge-success f-12">
                                                   <i class="fas fa-tshirt" aria-hidden="true"></i>&nbsp;&nbsp;{{styleCosting?.preProSampleApprovedPrinter}}</span>
                                             </div>                                             
                                                <div style="position: absolute;" *ngIf="styleCosting.status==='CANCELLED'" >
                                                    <img src="assets/cancelledBanner.png" 
                                                        class="" width="auto" height="70">
                                                </div>
                                                <img [src]="styleCosting.product?.productImages[0]?.thumbnailSignedUrl" 
                                                class="img-thumbnail img-fluid zoom-4 "
                                                style="display: block;  width:60px;  height:100px; min-width: 100px;"
                                                [style.border-color]="'cadetblue'"
                                                (error)="handleThumbnailError(styleCosting.product?.productImages[0],$event)" />
                                            </div>
                                        </div>
                                  </td>
                                    <td>{{blankSizeQty.styleSize}} {{blankSizeQty.styleOverrideSize?'('+blankSizeQty.styleOverrideSize+')':''}}</td>
                                    <td *ngIf="salesOrder.orderType !=='Estimation'">{{blankSizeQty.orderQty}}</td>
                                    <td class="p-0" *ngIf="salesOrder.orderType ==='Estimation'">
                                       <!--Estimation QTy Update-->
                                       <input name="bsoq{{sci}}{{bsqi}}" id="bsoq{{sci}}{{bsqi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #bsoqVar="ngModel" required 
                                        [customMin]="0" (keyup)="onKeyupOrderQuantityChange($event, bsoqVar.value, styleCosting, blankSizeQty.styleSize)"
                                        [disabled]="!editable"
                                        [(ngModel)]="blankSizeQty.orderQty" [ngClass]="{'is-invalid':bsoqVar.touched && bsoqVar.errors}" 
                                        
                                        (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(bsoqVar.name,'bsoq','DOWN')" 
                                        (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(bsoqVar.name,'bsoq','UP')"
                                        (keyup.alt.arrowdown)="propogateStyleQuantity(bsoqVar.value,styleCosting)"
                                        >
                                   </td>                                    
                                    <td class="p-0">
                                        <input name="bseq{{sci}}{{bsqi}}" id="bseq{{sci}}{{bsqi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #bseqVar="ngModel" required 
                                        [disabled]="!(editable && styleCosting.extraQtyType==='Manual')" [customMin]="0"
                                        [(ngModel)]="blankSizeQty.extraQty" [ngClass]="{'is-invalid':bseqVar.touched && bseqVar.errors}" (change)="manualExtraQtyChanged(styleCosting)" (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(bseqVar.name,'bseq','DOWN')" (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(bseqVar.name,'bseq','UP')"><!--(change)="onChangeSinglePONumberInput()"-->
                                    </td>
                                    <td>{{blankSizeQty.totalBlankQty}}</td>
                                    <ng-container *ngFor="let printerCombo of styleCosting.printerCombDetails;index as pcdi">
                                        <ng-container *ngFor="let printer of printerCombo.printerDetails; index as pi">
                                            <ng-container *ngIf="printer.printerSizeDetails">
                                                <ng-container *ngFor="let printerSizeDetails of printer.printerSizeDetails;index as psdi">
                                                    <td class="p-0" *ngIf="printerCombo.allocated && printerSizeDetails.size===blankSizeQty.styleSize">
                                                        <input name="psc{{pcdi}}{{pi}}{{sci}}{{psdi}}" id="psc{{pcdi}}{{pi}}{{sci}}{{psdi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #pscVar="ngModel"
                                                         [required]="printerSizeDetails.qty>0" [disabled]="!editable || printerSizeDetails.qty===0"
                                                        [(ngModel)]="printerSizeDetails.cost" placeholder="$" (change)="calculateAllocationSheet(costingAllocation)" [customMin]="0.00001"
                                                        (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(pscVar.name,'psc'+pcdi+pi,'DOWN')" 
                                                        (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(pscVar.name,'psc'+pcdi+pi,'UP')"
                                                        
                                                        (keyup.alt.arrowdown)="propogatePrinterCost(printer,pscVar.value);"
                                                        (keydown.shift.control)="$event.preventDefault();openCalculator(printerSizeDetails, 'cost', costingAllocation);"
                                                        (keydown.control.space)="$event.preventDefault();openCalculator(printerSizeDetails, 'cost', costingAllocation);"

                                                        [ngClass]="{'is-invalid':pscVar.touched && pscVar.errors}" ><!--(change)="onChangeSinglePONumberInput()"-->
                                                    </td>
                                                    <td class="p-0" *ngIf="!printerCombo.allocated && printerSizeDetails.size===blankSizeQty.styleSize">
                                                        &nbsp;
                                                    </td>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                
                                    <ng-container *ngFor="let bSupplierCombo of styleCosting.bSupplierCombDetails;index as pcdi">
                                        <ng-container *ngFor="let bSupplier of bSupplierCombo.bSupplierDetails; index as bsi">
                                            <ng-container *ngIf="bSupplier.blankSizeDetails">
                                                <ng-container *ngFor="let blankSizeDetails of bSupplier.blankSizeDetails;index as psdi">
                                                    <ng-container *ngIf="bSupplierCombo.allocated && blankSizeDetails.size===blankSizeQty.styleSize">
                                                        <td class="p-0">
                                                            <input name="bsq{{pcdi}}{{bsi}}{{sci}}{{psdi}}" id="bsq{{pcdi}}{{bsi}}{{sci}}{{psdi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #bsqVar="ngModel" required 
                                                            [disabled]="!editable" [customMin]="0"
                                                            [(ngModel)]="blankSizeDetails.qty" placeholder="Qty"
                                                            (change)="calculateAllocationSheet(costingAllocation)" 
                                                            (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(bsqVar.name,'bsq'+pcdi+bsi,'DOWN')" 
                                                            (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(bsqVar.name,'bsq'+pcdi+bsi,'UP')"
                                                            [ngClass]="{'is-invalid':isBlankAllocationMismatch(styleCosting,bSupplierCombo,blankSizeQty) || (bsqVar && bsqVar.errors)}"
                                                           ><!--(change)="onChangeSinglePONumberInput()"-->
                                                            <div *ngIf="isBlankAllocationMismatch(styleCosting,bSupplierCombo,blankSizeQty)" class="invalid-feedback">
                                                                Qty Mismatch {{mismatchBlankAllocationCount(styleCosting,bSupplierCombo,blankSizeQty)}}
                                                             </div>
                                                        </td>
                                                        <td class="p-0" >
                                                            <input name="bsc{{pcdi}}{{bsi}}{{sci}}{{psdi}}" id="bsc{{pcdi}}{{bsi}}{{sci}}{{psdi}}" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #bscVar="ngModel" 
                                                            [required]="blankSizeDetails.qty>0" [disabled]="!editable" *ngIf="blankSizeDetails.qty>0"
                                                            [(ngModel)]="blankSizeDetails.cost" [customMin]="0.00001" placeholder="$"
                                                            (change)="calculateAllocationSheet(costingAllocation)" 
                                                            (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(bscVar.name,'bsc'+pcdi+bsi,'DOWN')" 
                                                            (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(bscVar.name,'bsc'+pcdi+bsi,'UP')"
                                                            [ngClass]="{'is-invalid': bscVar.errors}" 
                                                            (keyup.alt.arrowdown)="propogateBlankCost(bSupplier,bscVar.value);"
                                                            (keydown.shift.control)="$event.preventDefault();openCalculator(blankSizeDetails, 'cost', costingAllocation);"
                                                            (keydown.control.space)="$event.preventDefault();openCalculator(blankSizeDetails, 'cost', costingAllocation);"
                                                            >
                                                            <div *ngIf="blankSizeDetails.qty===0">&nbsp;</div>
                                                            <!--  bscVar.touched && (change)=propogateBlankCost(bSupplier,bscVar.value);-->
                                                        </td>
                                                        <td class="p-0" >
                                                            <input name="bsbc{{pcdi}}{{bsi}}{{sci}}{{psdi}}" id="bsbc{{pcdi}}{{bsi}}{{sci}}{{psdi}}" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #bsbcVar="ngModel" 
                                                            [required]="blankSizeDetails.qty>0" [disabled]="!editable" *ngIf="blankSizeDetails.qty>0"
                                                            [(ngModel)]="blankSizeDetails.blankCode" placeholder="CL-BL-XXXX..." (change)="sanitizeBlankCode(blankSizeDetails)"
                                                            (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(bsbcVar.name,'bsbc'+pcdi+bsi,'DOWN')" 
                                                            (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(bsbcVar.name,'bsbc'+pcdi+bsi,'UP')"
                                                            [ngClass]="{'is-invalid':bsbcVar.errors}" 
                                                            (keyup.alt.arrowdown)="propogateBlankCode(bSupplier,bsbcVar.value)"><!--(change)="propogateBlankCode(bSupplier,bsbcVar.value)"-->
                                                            <div *ngIf="blankSizeDetails.qty===0">&nbsp;</div>
                                                        </td>
                                                        <td class="p-0" >
                                                            <!--<input name="bsos{{pcdi}}{{bsi}}{{sci}}{{psdi}}" id="bsos{{pcdi}}{{bsi}}{{sci}}{{psdi}}" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #bsosVar="ngModel" required [disabled]="!editable"
                                                            [(ngModel)]="blankSizeDetails.overrideSize" 
                                                            (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(bsosVar.name,'bsos'+pcdi+bsi,'DOWN')" 
                                                            (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(bsosVar.name,'bsos'+pcdi+bsi,'UP')"
                                                            [ngClass]="{'is-invalid':bsosVar.touched && bsosVar.errors}" >--><!--(change)="onChangeSinglePONumberInput()"-->
                
                                                            <select class="form-control cell-min-nowrap form-control-sm" style="font-size:11px;" *ngIf="blankSizeDetails.qty>0"
                                                            [style]="blankSizeDetails.overrideSize!==blankSizeDetails.size?'font-weight:bold;color:tomato':''"
                                                             [(ngModel)]="blankSizeDetails.overrideSize"
                                                             name="bsos{{pcdi}}{{bsi}}{{sci}}{{psdi}}" #bsosVar
                                                             [required]="blankSizeDetails.qty>0" [disabled]="!editable || blankSizeDetails.qty===0">
                                                                <option *ngFor="let sizeFromMetadata of sizesMetadataList" [ngValue]="sizeFromMetadata.size" [disabled]="!sizeFromMetadata.status">
                                                                    {{sizeFromMetadata.size}}</option>
                                                            </select>
                                                       <div *ngIf="blankSizeDetails.qty===0">&nbsp;</div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container *ngIf="!bSupplierCombo.allocated && blankSizeDetails.size===blankSizeQty.styleSize">
                                                        <td class="p-0">&nbsp;</td>
                                                        <td class="p-0">&nbsp;</td>
                                                        <td class="p-0">&nbsp;</td>
                                                        <td class="p-0">&nbsp;</td>
                                                    </ng-container>
                                                    
                                                    
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                
                                    
                                </tr>
                                
                                <ng-container *ngIf="styleCosting.printerCombDetails && styleCosting.printerCombDetails[0]?.printerDetails[0]?.costTypeDetails?.length>0">
                                    <input type="number" style="display: none;" #firstValidIndex [value]="getUserInputIndex(styleCosting.printerCombDetails[0]?.printerDetails[0]?.costTypeDetails)">        
                                    <ng-container *ngFor="let pslctd of styleCosting.printerCombDetails[0]?.printerDetails[0]?.costTypeDetails;index as pscltdi">
                                        
                                    <tr *ngIf="pslctd.source==='USER_INPUT'">
                                        <th colspan="5" class="text-right">{{pslctd.description}}
                                            
                                                <span *ngIf="pslctd.calculationType==='PERCENTAGE'" class="text-muted">(%)</span>
                                                <span *ngIf="pslctd.calculationType==='ABSOLUTE_UNIT'" class="text-muted">($ / EA)</span>
                                                <span *ngIf="pslctd.calculationType==='ABSOLUTE'" class="text-muted">(Total Cost/Style)</span>
                                            
                                        </th>
                                        <ng-container *ngFor="let pcomb of styleCosting.printerCombDetails; index as pcombi"> 
                                            <ng-container *ngFor="let pd of pcomb.printerDetails; index as pdi">
                                                <!--<ng-container *ngFor="let ct of pd.costTypeDetails; index as pdctdi">
                                                    {{pscltdi}}/{{pd.costTypeDetails.length}}
                                                    <td class="p-0" *ngIf="pdctdi===pscltdi">
                                                        {{ct|json}}
                                                    </td>
                                                </ng-container>    -->
                                                <td class="p-0" *ngIf="pcomb.allocated && pd.costTypeDetails && pd.costTypeDetails.length>0">
                                                    <input name="psc{{pcombi}}{{pdi}}{{sci}}{{pscltdi}}ltd" id="psc{{pcombi}}{{pdi}}{{sci}}{{pscltdi}}ltd" type="number" class="form-control form-control-sm cell-min-nowrap" style="font-size: 11px;" #pslctdVar="ngModel" required 
                                                    [disabled]="!editable"
                                                                [(ngModel)]="pd.costTypeDetails[pscltdi].uiCost" [customMin]="0"
                                                                (keydown.arrowdown)="$event.preventDefault();$event.target.blur();tableArrowMove(pslctdVar.name,'psc'+pcombi+pdi,'DOWN')" 
                                                                (keydown.arrowup)="$event.preventDefault();$event.target.blur();tableArrowMove(pslctdVar.name,'psc'+pcombi+pdi,'UP')"
                                                     [ngClass]="{'is-invalid':pslctdVar.touched && pslctdVar.errors}" 
                                                     (change)="calculateAllocationSheet(costingAllocation)"
                                                     (keydown.control.space)="$event.preventDefault();openCalculator(pd.costTypeDetails[pscltdi], 'uiCost', costingAllocation);">
                                                </td>
                                                <td class="p-0" *ngIf="!pcomb.allocated">
                                                    &nbsp;
                                                </td>
                                                
                                            </ng-container>
                                        </ng-container>
                                        <!--Notes starts-->
                                    <ng-container>
                                        <ng-container *ngIf="firstValidIndex.value===pscltdi+''">
                                            
                                            <td [attr.colspan]="9999" [attr.rowspan]="9999"  class=" bg-light">
                                                <div style="max-width: calc(50vw);">
                                                    <app-notes [editable]="true" [vh]="25" [parentId]="styleCosting.orderDetailId" [type]="'CA_OD_NOTES'" [subType]="'ALLO_SHEET'" [notesHeaderText]="'Notes'"></app-notes>  
                                                </div>
                                            </td>
                                            
                                        </ng-container>
                                    </ng-container>
                                    <!--Notes Ends-->
                                    </tr>
                                    </ng-container>
                                    
                                </ng-container>
                                <ng-container *ngIf="!styleCosting.printerCombDetails || styleCosting.printerCombDetails.length===0">
                                        
                                    <tr>
                                        <th colspan="5" rowspan="10">
                                            &nbsp;
                                        </th>
                                        
                                        <!--Notes starts-->
                                            
                                            <td [attr.colspan]="9999" [attr.rowspan]="9999"  class=" bg-light">
                                                <div style="max-width: calc(50vw);">
                                                    <app-notes [editable]="true" [vh]="20" [parentId]="styleCosting.orderDetailId" [type]="'CA_OD_NOTES'" [subType]="'ALLO_SHEET'" [notesHeaderText]="'Notes'"></app-notes>  
                                                </div>
                                            </td>
                                            
                                    <!--Notes Ends-->
                                    </tr>
                                    
                                    
                                </ng-container>
                                
                            
                
                        </tbody>
                    </table>
                </div>
                </ng-container>
                </form>

            </div>
        



    
        
</ng-container>
<!--<div class="floatBtn d-flex flex-column">
   <a class="btn btn-circle bg-dark mb-2" (click)="generateCostingSheetsCombinationForEachStyle()" *ngIf="true" matTooltip="Generate Cost Sheet(s) Summary" matTooltipPosition="left"
      matTooltipClass="bg-dark"> <i class="fas fa-file-alt fa-2x" style="color: white;"></i></a>
</div>-->