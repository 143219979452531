import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PrinterSizeDetailsForSummary } from 'src/app/shared/model/sales/costing/allocation/printer-size-details.model';


@Component({
  selector: 'app-sku-printer-assignment-modal',
  templateUrl: './sku-printer-assignment-modal.component.html',
  styleUrls: ['./sku-printer-assignment-modal.component.css']
})
export class SkuPrinterAssignmentModalComponent implements OnInit {

  

  
  skuPrinterAssignmentList:any[];
  title:string;

   constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<SkuPrinterAssignmentModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = false;

      if (data.skuPrinterAssignmentList) {
         this.skuPrinterAssignmentList = data.skuPrinterAssignmentList;
      }
      if (data.title) {
        this.title = data.title;
     }
   }

   getBlanksUsed(psd:PrinterSizeDetailsForSummary){
    let blanksDetails:string='';
    if(psd && psd.printerBlanksDistributionSizeDetailsList && psd.printerBlanksDistributionSizeDetailsList.length>0){
        psd.printerBlanksDistributionSizeDetailsList.forEach(pbdsd => {
          let detail=pbdsd.blankCode+(pbdsd.overrideSize!==pbdsd.size?'('+pbdsd.overrideSize+')':'');
          if(blanksDetails!==''){
            blanksDetails=blanksDetails+' \n ';
          }
          blanksDetails=blanksDetails+detail;
        });
    }
   return blanksDetails;
   }

   ngOnInit(): void {
  }


}
