import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, Subject } from 'rxjs';
import { SelectFromListDialogComponent } from 'src/app/common/select-from-list-dialog/select-from-list-dialog.component';
import { AllocatedBlankSupplierCombinationDetails } from 'src/app/shared/model/sales/costing/allocation/allocated-blank-supplier-combination-details.model';
import { AllocatedBlankSupplierDetails } from 'src/app/shared/model/sales/costing/allocation/allocated-blank-supplier-details.model';
import { AllocatedPrinterCombinationDetails } from 'src/app/shared/model/sales/costing/allocation/allocated-printer-combination-details.model';
import { AllocatedPrinterDetails } from 'src/app/shared/model/sales/costing/allocation/allocated-printer-details.model';
import { BlankSizeDetails } from 'src/app/shared/model/sales/costing/allocation/blank-size-details.model';
import { BlankSizeQuantity } from 'src/app/shared/model/sales/costing/allocation/blank-size-quantity.model';
import { CostingAllocation } from 'src/app/shared/model/sales/costing/allocation/costing-allocation.model';
import { PrinterSizeDetails } from 'src/app/shared/model/sales/costing/allocation/printer-size-details.model';
import { StyleCosting } from 'src/app/shared/model/sales/costing/allocation/style-costing.model';
import { CostTypeDetail } from 'src/app/shared/model/sales/costsheet/cost-type-detail.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { BlanksSupplier } from 'src/app/shared/model/vendors/blanks-supplier.model';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';
import { SalesOrderCostingService } from 'src/app/shared/service/sales/sales-order-costing.service';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { CostingSheet } from 'src/app/shared/model/sales/costing/costingSheet/costing-sheet.model';
import { CostingHelperService } from 'src/app/shared/service/sales/costing-helper.service';
import { ProductAttachment, Product } from 'src/app/shared/model/product.model';
import { ToastrService } from 'ngx-toastr';
import { CanComponentDeactivate } from 'src/app/auth/guards/deactivate.gaurd';
import { template } from 'lodash';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { OrderDetail } from 'src/app/shared/model/sales/order-detail.model';
import { OrderDetailViewerDialogComponent } from 'src/app/common/order-detail-viewer-dialog/order-detail-viewer-dialog.component';
import { SkuPrinterAssignmentModalComponent } from '../sku-printer-assignment-modal/sku-printer-assignment-modal.component';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';
import { MetadataSize } from 'src/app/shared/model/product-metadata/metadata-size.model';

@Component({
  selector: 'app-allocation-sheet',
  templateUrl: './allocation-sheet.component.html',
  styleUrls: ['./allocation-sheet.component.css']
  /*,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]*/
})
export class AllocationSheetComponent implements OnInit, CanComponentDeactivate {

  //selectedTab:number=0;
  conversionBufferPoints:number=0.03;
  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;

  //sizesListFromMetadata: string[] = [];
  sizesMetadataList: MetadataSize[] = [];
  cooListFromMetadata:string[]=[];
  printerJobTypeFromMetadata:string[]=[];
  productionStatusForCostingPendingApproval:string;
  productionStatusForCostingApproved:string;
  productionStatusForCostingPending:string;
  productionStatusForEstimationAcknowledgment:string;
  userInputStyleCostIndex: number;
  blankSummaryEventsSubject: Subject<string> = new Subject<string>();

   constructor(private costingService: SalesOrderCostingService, private dialog: MatDialog, private salesService: SalesService, private authService: AuthService
      , private salesHelperService: SalesHelperService, private costingHelperService: CostingHelperService, private toastrService: ToastrService,
      private sharedService:SharedService,private router: Router,private productMetadataService:ProductMetadataService) { }
  
  confirm(): boolean {
    return (!this.editable);
  }
      @Input() events: Observable<string>;
   costingAllocation: CostingAllocation;
   editable: boolean;
   @Input() salesOrderId: string;
   @Input() salesOrder: SalesOrder;
   @Output() statusChanged = new EventEmitter<string>();
   freshCostTypeDetailListForHeaders: CostTypeDetail[] = [];
   freshCostTypeDetailListForOrganization: CostTypeDetail[] = [];
   freshCostTypeDetailListForCustomer: CostTypeDetail[] = [];
   freshCostTypeDetailListForPrinterTopLevel: CostTypeDetail[] = [];
   freshCostTypeDetailListForPrinterStyleLevel: CostTypeDetail[] = [];
   freshCostTypeDetailListForSupplierTopLevel: CostTypeDetail[] = [];
   freshCostTypeDetailListForSupplierStyleLevel: CostTypeDetail[] = [];

   async ngOnInit() {
      this.handleSubscriptions();
      this.events.subscribe(async event => {
        if(event==='RELOAD' && !this.editable){
          await this.loadCostTypeDetailList(this.salesOrder);
          await this.getCostingAllocation(this.salesOrderId);
          if(this.salesOrder.productionStatus===this.productionStatusForCostingPendingApproval){
            //this.selectedTab=1;
          }
        }
      })
      
      //do not remove
      this.productionStatusForCostingPendingApproval=this.salesHelperService.productionStatusForCostingPendingApproval
      this.productionStatusForCostingApproved=this.salesHelperService.productionStatusForCostingApproved;
      this.productionStatusForCostingPending=this.salesHelperService.productionStatusForCostingPending;
      this.productionStatusForEstimationAcknowledgment=this.salesHelperService.productionStatusForEstimationAcknowledgment;
   }

  private async loadCostTypeDetailList(salesOrder:SalesOrder) {
    await this.loadCostTypeDetailListForHeaders('HEADER', 'HEADER',salesOrder);
    await this.loadCostTypeDetailListForOrganization('ORG', 'ORG',salesOrder);
    await this.loadCostTypeDetailListForCustomer('CUSTOMER', 'TOP_INDIVIDUAL',salesOrder);
    await this.loadCostTypeDetailListForPrinterTopLevel('PRINTER', 'TOP_INDIVIDUAL',salesOrder);
    await this.loadCostTypeDetailListForSupplierTopLevel('SUPPLIER', 'TOP_INDIVIDUAL',salesOrder);
    await this.loadCostTypeDetailListForPrinterStyleLevel('PRINTER', 'STYLE_COMBINATION_AVERAGE',salesOrder);
    await this.loadCostTypeDetailListForSupplierStyleLevel('SUPPLIER', 'STYLE_COMBINATION_AVERAGE',salesOrder);
  }

   async edit() {
      
      await this.getCostingAllocation(this.salesOrderId);
      //this.loadCostTypeDetailList();
      if(this.costingAllocation.dirtyFlag){
        this.costingAllocation.dirtyFlag=false;
      }
      if(this.costingAllocation.approvalStatus==='APPROVED'){
        this.costingAllocation.approvalStatus='NOT_APPROVED'
      }
      this.sanitizeCostingAllocation(this.costingAllocation);
      if(!this.costingAllocation.exchangeRateUsdToCad || this.costingAllocation.exchangeRateUsdToCad===null || this.costingAllocation.exchangeRateUsdToCad===0){
        this.getCurrencyConversion('FXUSDCAD')
      }
      if(!this.costingAllocation.exchangeRateCadToUsd || this.costingAllocation.exchangeRateCadToUsd===null || this.costingAllocation.exchangeRateCadToUsd===0){
          this.getCurrencyConversion('FXCADUSD')
      }
      this.editable = true;
      
   }

   costingMode: string = 'REGULAR';
   async exploreCosting() {
      await this.edit();
      
      this.costingMode = 'EXPLORE';
      /*
      if (this.costingAllocation && this.costingAllocation.styleCosting && this.costingAllocation.styleCosting.length > 0) {
         this.costingAllocation.styleCosting.forEach(existingSc => {
            let existingSellingPricePerUnit: number = existingSc.sellingPricePerUnit;
            existingSc.originalSellingPricePerUnit = existingSellingPricePerUnit;
            existingSc.suggestedSellingPricePerUnit = existingSellingPricePerUnit;
         });
      }
      */
   }
   /*
   onSuggestedSellingPriceChange(styleCosting: StyleCosting, costingAllocation: CostingAllocation) {
      styleCosting.sellingPricePerUnit = styleCosting.suggestedSellingPricePerUnit;
      this.calculateAllocationSheet(costingAllocation);
   }
   */


   onSellingPriceChange(styleCosting: StyleCosting, costingAllocation: CostingAllocation) {
      this.calculateAllocationSheet(costingAllocation);
   }


   async cancelExploreCosting() {
      this.editable = false;
      this.costingMode = 'REGULAR';
      if (this.costingAllocation && this.costingAllocation.styleCosting && this.costingAllocation.styleCosting.length > 0) {
         this.costingAllocation.styleCosting.forEach(existingSc => {
            let originalSellingPricePerUnit: number = existingSc.originalSellingPricePerUnit;
            existingSc.sellingPricePerUnit = originalSellingPricePerUnit;
         });
      }
      await this.getCostingAllocation(this.salesOrderId);
   }


   getCurrencyConversion(type:string) {
    this.salesService.getRatesConversion(type).subscribe(res=>{
       if(res && res.observations && res.observations.length>0 && res.observations[0] ){
          if(type==='FXUSDCAD'){
            this.costingAllocation.exchangeRateUsdToCad=Number(res.observations[0].FXUSDCAD.v) + this.conversionBufferPoints;
            this.costingAllocation.exchangeRateUsdToCad=this.costingHelperService.roundUp(this.costingAllocation.exchangeRateUsdToCad,3);
          }
          if(type==='FXCADUSD'){
            this.costingAllocation.exchangeRateCadToUsd=Number(res.observations[0].FXCADUSD.v) - this.conversionBufferPoints;
            this.costingAllocation.exchangeRateCadToUsd=this.costingHelperService.roundUp(this.costingAllocation.exchangeRateCadToUsd,3);
          }
          this.calculateAllocationSheet(this.costingAllocation);

       }
    });
 }
 
   async cancelEdit() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: 'Cancel Edit',
        message: 'The changes done will be lost. Do you want to continue?', 
        confirmBtnText: '  Yes  ',
        cancelBtnText:'   No   '
      }
    });
    confirmDialog.afterClosed().toPromise().then(async result => {
      if (result === true) {
        this.editable = false;
      await this.getCostingAllocation(this.salesOrderId);
      }
    });
      
   }

  sanitizeCostingAllocation(ca: CostingAllocation) {
    if(ca && ca.styleCosting && ca.styleCosting.length>0 ){
      ca.styleCosting.forEach(existingSc => {
        /*** Update orderQty for each PrinterSize Details Starts*/
        if (existingSc.printerCombDetails && existingSc.printerCombDetails.length > 0) {
          existingSc.printerCombDetails.forEach(pc => {
             pc.printerDetails.forEach(pd => {
                pd.printerSizeDetails.forEach(psdl => {
                   let arr = existingSc.blankSizeQty.filter(x => x.styleSize === psdl.size);
                   if (arr && arr.length === 1) {
                     psdl.qty=arr[0].orderQty;
                   }
                });
          });
       });
       }
       /*** Update orderQty for each PrinterSize Details Ends*/

       /*** Handle new size to StyleCosting Starts*/
        if (existingSc.printerCombDetails && existingSc.printerCombDetails.length>0 && existingSc.printerCombDetails[0].printerDetails && existingSc.printerCombDetails[0].printerDetails[0]) {
          let missingBsq:BlankSizeQuantity[] = [];
          existingSc.blankSizeQty.forEach(esBsq => {
            let arr = existingSc.printerCombDetails[0].printerDetails[0].printerSizeDetails?.filter(x => x.size === esBsq.styleSize);
            if (arr && arr.length === 0) {
              missingBsq.push(esBsq);
            }
          });
          if(missingBsq.length>0){
            existingSc.printerCombDetails.forEach(pcomb => {
              missingBsq.forEach(newBsq => {
                let newAveragePrinterSizeDetails=this.createPrinterSizeDetails(newBsq);
                pcomb.averagePrinterSizeDetails.push(newAveragePrinterSizeDetails);
                if(pcomb.printerDetails && pcomb.printerDetails.length>0){
                    pcomb.printerDetails.forEach(printerDetail => {
                      let newPrinterSizeDetails=this.createPrinterSizeDetails(newBsq);
                      printerDetail.printerSizeDetails.push(newPrinterSizeDetails);
                  }); 
                }
              });
            });
          }
          if(missingBsq.length>0){
            let counter:number=0;
            if(existingSc.bSupplierCombDetails && existingSc.bSupplierCombDetails.length>0){
            existingSc.bSupplierCombDetails.forEach(bSuppComb => {
              counter++;
              missingBsq.forEach(newBsq => {
                let newAverageBlankSizeDetails=this.createBlankSizeDetails(newBsq);
                bSuppComb.averageBlankSizeDetails.push(newAverageBlankSizeDetails);
                if(bSuppComb.bSupplierDetails && bSuppComb.bSupplierDetails.length>0){
                  bSuppComb.bSupplierDetails.forEach(bSuppDetail => {
                      let newBlankSizeDetails=this.createBlankSizeDetails(newBsq,counter);
                      bSuppDetail.blankSizeDetails.push(newBlankSizeDetails);
                  }); 
                }
              });
            });
          }
          }
        }
        /*** Handle new size to StyleCosting Ends*/
      });

      /*
      ca.styleCosting.forEach(sc => {
        if(sc.status!=='CANCELLED'){
        if(!sc.printerCombDetails || (sc.printerCombDetails && sc.printerCombDetails.length===0 )){
          if(ca.styleCosting[0].printerCombDetails && ca.styleCosting[0].printerCombDetails.length>0){
            ca.styleCosting[0].printerCombDetails.forEach(existingPrinterComb => {
              let selectedPrinterForComb:Printer[]=[];
              existingPrinterComb.printerDetails.forEach(allocatedPrinterDetails => {
                let arr=this.costingService.printerList.filter(x=>x.id===allocatedPrinterDetails.printerId);
                if(arr && arr.length>0){
                  selectedPrinterForComb.push(arr[0]);
                }  
              });
              this.createPrinterCombinationForStyle(selectedPrinterForComb,sc)
            });
          }
        }

        if(!sc.bSupplierCombDetails || (sc.bSupplierCombDetails && sc.bSupplierCombDetails.length===0 )){
          if(ca.styleCosting[0].bSupplierCombDetails && ca.styleCosting[0].bSupplierCombDetails.length>0){
            ca.styleCosting[0].bSupplierCombDetails.forEach(existingbSupplierComb => {
              let selectedBlankSupplierForComb:BlanksSupplier[]=[];
              existingbSupplierComb.bSupplierDetails.forEach(allocatedbSupplierDetails => {
                let arr=this.costingService.blanksSupplierList.filter(x=>x.id===allocatedbSupplierDetails.supplierId);
                if(arr && arr.length>0){
                  selectedBlankSupplierForComb.push(arr[0]);
                }  
              });
              this.createBlankSupplierCombinationForStyle(selectedBlankSupplierForComb,sc)
            });
          }
        }
      }
      });*/
    }
    this.calculateAllocationSheet(ca);
  }
  async loadCostTypeDetailListForHeaders(classification: string, subClassification: string,salesOrder:SalesOrder) {
    await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
       if (response.responseStatus.status === 'SUCCESS') {
          let tmpCostTypeDetailList = response.responsePayload;
          this.freshCostTypeDetailListForHeaders = tmpCostTypeDetailList;
       }
    });
 }

 async loadCostTypeDetailListForOrganization(classification: string, subClassification: string,salesOrder:SalesOrder) {
   await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
       if (response.responseStatus.status === 'SUCCESS') {
          let tmpCostTypeDetailList = response.responsePayload;
          this.freshCostTypeDetailListForOrganization = tmpCostTypeDetailList;
       }
    });
 }

 async loadCostTypeDetailListForCustomer(classification: string, subClassification: string,salesOrder:SalesOrder) {
    await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
       if (response.responseStatus.status === 'SUCCESS') {
          let tmpCostTypeDetailList = response.responsePayload;
          this.freshCostTypeDetailListForCustomer = tmpCostTypeDetailList;
       }
    });
 }

 async loadCostTypeDetailListForPrinterTopLevel(classification: string, subClassification: string,salesOrder:SalesOrder) {
    await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
       if (response.responseStatus.status === 'SUCCESS') {
          let tmpCostTypeDetailList = response.responsePayload;
          this.freshCostTypeDetailListForPrinterTopLevel = tmpCostTypeDetailList;
       }
    });
 }

 async loadCostTypeDetailListForPrinterStyleLevel(classification: string, subClassification: string,salesOrder:SalesOrder) {
    await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
       if (response.responseStatus.status === 'SUCCESS') {
          let tmpCostTypeDetailList = response.responsePayload;
          this.freshCostTypeDetailListForPrinterStyleLevel = tmpCostTypeDetailList;
       }
    });
 }

 async loadCostTypeDetailListForSupplierTopLevel(classification: string, subClassification: string,salesOrder:SalesOrder) {
    await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
       if (response.responseStatus.status === 'SUCCESS') {
          let tmpCostTypeDetailList = response.responsePayload;
          this.freshCostTypeDetailListForSupplierTopLevel = tmpCostTypeDetailList;
       }
    });
 }

 async loadCostTypeDetailListForSupplierStyleLevel(classification: string, subClassification: string,salesOrder:SalesOrder) {
    await this.costingService.loadCostTypes(classification, subClassification,salesOrder.currency).toPromise().then(response => {
       if (response.responseStatus.status === 'SUCCESS') {
          let tmpCostTypeDetailList = response.responsePayload;
          this.freshCostTypeDetailListForSupplierStyleLevel = tmpCostTypeDetailList;
       }
    });
 }


  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
       this.currentLoggedInUser = currentLoggedInUser;
    });
    this.productMetadataService.sizesMetadataListObservable.subscribe(res => {
       this.sizesMetadataList = res;
    });
    this.salesHelperService.cooListFromMetadata.subscribe(res => {
      this.cooListFromMetadata = res;
   });
   this.salesHelperService.printerJobTypeFromMetadata.subscribe(res => {
    this.printerJobTypeFromMetadata = res;
 });
    
 }

   getOrderDetailById(id: string) {
      if (this.salesOrder) {
         let retVal = this.salesOrder.orderDetails.filter(od => od.id === id);
         if (retVal && retVal.length === 1) {
            return retVal[0];
         }
      }
   }

   async  getCostingAllocation(salesOrderId: string) {
      await this.costingService.getAllocationSheet(salesOrderId).toPromise().then(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let costingAllocation: CostingAllocation = response.responsePayload as CostingAllocation;
            this.costingAllocation = costingAllocation;
            this.calculateAllocationSheet(this.costingAllocation);
            this.populatePreProSampleApprovedPrinter(this.costingAllocation);
         }
      });
   }

  loadPrinterSelectionDialog(styleCosting?:StyleCosting) {
    //console.log('this.loadPrinterSelectionDialog',JSON.stringify(this.costingService.printerList));
    let selectableOptions:any[]=[{key:"ACAS",desc:"Add Selected Combination to All Styles",value:false}];
    const confirmDialog = this.dialog.open(SelectFromListDialogComponent, {
      disableClose: false,
      data: {
        title: 'Allocate Printer/Printer Combination',
        modalType: 'MULTI_SELECT',
        cancelBtnText: 'Close',
        confirmBtnText: 'Confirm Selection ',
        message: undefined,
        selectionList: this.costingService.getPrinterList(),
        selectableOptions:selectableOptions
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result && result.length>0) {
        let acad=selectableOptions.filter(x=>x.key==='ACAS');
        if(acad[0].value===true){
          this.addPrinterCombination(result);
        }else{
          this.addPrinterCombination(result,styleCosting);
        }
       
      } else {
      }
    });
  }

  loadBlankSupplierSelectionDialog(styleCosting?:StyleCosting) {
    let selectableOptions:any[]=[{key:"ACAS",desc:"Add Selected Combination to All Styles",value:false}];
    const confirmDialog = this.dialog.open(SelectFromListDialogComponent, {
      disableClose: false,
      data: {
        title: 'Allocate Blank Supplier/Blank Supplier Combination',
        modalType: 'MULTI_SELECT',
        cancelBtnText: 'Close',
        confirmBtnText: 'Confirm Selection ',
        message: undefined,
        selectionList: this.costingService.getBlankSupplierList(),
        selectableOptions:selectableOptions
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result && result.length>0) {
        let acad=selectableOptions.filter(x=>x.key==='ACAS');
        if(acad[0].value===true){
          this.addBlankSupplierCombination(result);
        }else{
          this.addBlankSupplierCombination(result,styleCosting);
        }
       
      } else {
      }
    });
  }

  addPrintertoCombinationDialog(sc:StyleCosting,combination:AllocatedPrinterCombinationDetails) {
    //console.log('this.addPrintertoCombinationDialog',JSON.stringify(this.costingService.printerList));
    const confirmDialog = this.dialog.open(SelectFromListDialogComponent, {
      disableClose: false,
      data: {
        title: 'Allocate Printer/Printer Combination',
        modalType: 'MULTI_SELECT',
        cancelBtnText: 'Close',
        confirmBtnText: 'Confirm Selection ',
        message: undefined,
        selectionList: this.costingService.getPrinterList()
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
    
      if (result && result.length>0) {
        this.addPrinterToAllocatedPrintersList(result);
        result.forEach(printer => {
          this.addPrinterToCombination(combination,printer,sc);
        });
       
      } else {
      }
    });
    this.calculateAllocationSheet(this.costingAllocation);
  }

  addBlankSuppliertoCombinationDialog(sc:StyleCosting,combination:AllocatedBlankSupplierCombinationDetails) {
    const confirmDialog = this.dialog.open(SelectFromListDialogComponent, {
      disableClose: false,
      data: {
        title: 'Allocate Blank Supplier/Blank Supplier Combination',
        modalType: 'MULTI_SELECT',
        cancelBtnText: 'Close',
        confirmBtnText: 'Confirm Selection ',
        message: undefined,
        selectionList: this.costingService.getBlankSupplierList()
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
    
      if (result && result.length>0) {
        this.addSuppliersToAllocatedSuppliersList(result);
        result.forEach(bs => {
          this.addBlankSupplierToCombination(bs,sc,combination.bSupplierDetails.length+1,combination);
        });
       
      } else {
      }
    });
    this.calculateAllocationSheet(this.costingAllocation);
  }

  

/*
  addPrinterCombination(){
    if(!this.costingAllocation.allocatedPrinters || this.costingAllocation.allocatedPrinters===null){
      this.costingAllocation.allocatedPrinters=[];
    }
    let printerId="1001"
    let printerName="Printer "+(this.costingAllocation.allocatedPrinters.length+1);
   
    this.costingAllocation.allocatedPrinters.push(printerName);
    this.costingAllocation.styleCosting.forEach(sc=>{
      let allocatedPrinterDetails:AllocatedPrinterDetails=new AllocatedPrinterDetails();
      allocatedPrinterDetails.printerId=printerId;
      allocatedPrinterDetails.printerName=printerName;
      let combination:AllocatedPrinterCombinationDetails=new AllocatedPrinterCombinationDetails();
      combination.printerDetails=[];
      combination.isAllocated=true;
      combination.printerDetails.push(allocatedPrinterDetails);
      combination.combinationName=allocatedPrinterDetails.printerName;
      let printerSizeDetails:PrinterSizeDetails[]=[];
      sc.blankSizeQty.forEach(bsq => {
        let printerSizeDetail:PrinterSizeDetails=new PrinterSizeDetails;
        printerSizeDetail.cost=0;
        printerSizeDetail.qty=bsq.orderQty;
        printerSizeDetail.size=bsq.styleSize;
        printerSizeDetails.push(printerSizeDetail);
      });
      combination.averagePrinterSizeDetails=printerSizeDetails;
      if(!sc.printerCombDetails || sc.printerCombDetails===null){
        sc.printerCombDetails=[];
      }
      sc.printerCombDetails.push(combination);
    })
   
  }
*/

  addBlankSupplierCombination(selectedBankSupplierForComb:BlanksSupplier[],styleCosting?:StyleCosting){
    if(!this.costingAllocation.allocatedSuppliers || this.costingAllocation.allocatedSuppliers===null){
      this.costingAllocation.allocatedSuppliers=[];
    }
    this.addSuppliersToAllocatedSuppliersList(selectedBankSupplierForComb);
    if(!styleCosting){
      this.costingAllocation.styleCosting.forEach(sc=>{
        if(sc.status!=='CANCELLED'){
          this.createBlankSupplierCombinationForStyle(selectedBankSupplierForComb, sc);
        }
      });
    }else{
      this.createBlankSupplierCombinationForStyle(selectedBankSupplierForComb, styleCosting);
    }
    this.calculateAllocationSheet(this.costingAllocation);
  }

  
  private addSuppliersToAllocatedSuppliersList(selectedBankSupplierForComb: BlanksSupplier[]) {
    selectedBankSupplierForComb.forEach(selectedblankSupplier => {
      let arr = this.costingAllocation.allocatedSuppliers.filter(x => x.supplierId === selectedblankSupplier.id);
      if (arr.length === 0) {
        let allocatedBlankSupplierDetails: AllocatedBlankSupplierDetails = new AllocatedBlankSupplierDetails('NA');
        if(!allocatedBlankSupplierDetails.currency || allocatedBlankSupplierDetails.currency===null){
          allocatedBlankSupplierDetails.currency = this.salesOrder.currency;
        }else{
         allocatedBlankSupplierDetails.currency = selectedblankSupplier.currency;
        }
        allocatedBlankSupplierDetails.supplierId = selectedblankSupplier.id;
        allocatedBlankSupplierDetails.supplierName = selectedblankSupplier.name;
        /*COstType*/
        allocatedBlankSupplierDetails.costTypeDetails = [];
        this.costingService.loadCostTypes('SUPPLIER', 'TOP_INDIVIDUAL', allocatedBlankSupplierDetails.currency, allocatedBlankSupplierDetails.supplierId).toPromise().then(response => {
          if (response.responseStatus.status === 'SUCCESS') {
            let costTypeDetails: CostTypeDetail[] = response.responsePayload;
            if (costTypeDetails && costTypeDetails.length > 0) {
              allocatedBlankSupplierDetails.costTypeDetails = costTypeDetails;
            }
          }
        });
        /*COstType*/
        this.costingAllocation.allocatedSuppliers.push(allocatedBlankSupplierDetails);
      }else{
        selectedblankSupplier.currency=arr[0].currency;
      }
    });
    this.costingHelperService.sanitizeFreightDetailsMatrix(this.costingAllocation);
  }

  private addPrinterToAllocatedPrintersList(selectedPrinterForComb: Printer[]) {
    //console.log('addPrinterToAllocatedPrintersList');
    selectedPrinterForComb.forEach(selectedPrinter => {
    let arr=this.costingAllocation.allocatedPrinters.filter(x=>x.printerId===selectedPrinter.id);
    if(arr.length===0){
      let allocatedPrinterDetails: AllocatedPrinterDetails = new AllocatedPrinterDetails('NA');
      if(!selectedPrinter.currency || selectedPrinter.currency===null){
        allocatedPrinterDetails.currency=this.salesOrder.currency;
      }else{
        allocatedPrinterDetails.currency=selectedPrinter.currency;//added with PO 
      }
      allocatedPrinterDetails.printerId = selectedPrinter.id;
      allocatedPrinterDetails.printerName = selectedPrinter.name;
      /*COstType*/
      allocatedPrinterDetails.costTypeDetails=[];
      this.costingService.loadCostTypes('PRINTER', 'TOP_INDIVIDUAL',allocatedPrinterDetails.currency, allocatedPrinterDetails.printerId).toPromise().then(response => {
        if (response.responseStatus.status === 'SUCCESS') {
          let costTypeDetails: CostTypeDetail[] = response.responsePayload;
          if (costTypeDetails && costTypeDetails.length > 0) {
            allocatedPrinterDetails.costTypeDetails = costTypeDetails;
          }
        }
      });
      /*COstType*/
      this.costingAllocation.allocatedPrinters.push(allocatedPrinterDetails);
    }else{
      selectedPrinter.currency=arr[0].currency;
    }
  });
  this.costingHelperService.sanitizeFreightDetailsMatrix(this.costingAllocation);
  }

  private createBlankSupplierCombinationForStyle(selectedBankSupplierForComb: BlanksSupplier[], sc: StyleCosting) {
    let combination: AllocatedBlankSupplierCombinationDetails = new AllocatedBlankSupplierCombinationDetails(this.sharedService.newGuid());
    combination.bSupplierDetails = [];
    combination.allocated = true;
    let counter: number = 0;
    selectedBankSupplierForComb.forEach(selectedblankSupplier => {
      counter++;
      this.addBlankSupplierToCombination(selectedblankSupplier, sc, counter, combination);
    });


    let avgBlankSizeDetails: BlankSizeDetails[] = [];
    sc.blankSizeQty.forEach(bsq => {
      let avgBlankSizeDetail: BlankSizeDetails = this.createBlankSizeDetails(bsq);
      avgBlankSizeDetails.push(avgBlankSizeDetail);
    });
    combination.averageBlankSizeDetails = avgBlankSizeDetails;
    if (!sc.bSupplierCombDetails || sc.bSupplierCombDetails === null) {
      sc.bSupplierCombDetails = [];
    }
    sc.bSupplierCombDetails.push(combination);
  }

  private createBlankSizeDetails(bsq: BlankSizeQuantity,counter?:number) {
    //DOnt send counter for combination average balnk size details
    let blankSizeDetail: BlankSizeDetails = new BlankSizeDetails;
    blankSizeDetail.cost = undefined;
    blankSizeDetail.qty = bsq.totalBlankQty;
    if (!counter || counter === 1) {
      blankSizeDetail.qty = bsq.totalBlankQty;
    } else {
      blankSizeDetail.qty = 0;
    }
    blankSizeDetail.size = bsq.styleSize;
      if(bsq.styleOverrideSize && bsq.styleOverrideSize!==null){
        blankSizeDetail.overrideSize = bsq.styleOverrideSize;
      }else{
        blankSizeDetail.overrideSize = bsq.styleSize;
      }
    
    return blankSizeDetail;
  }
  private createPrinterSizeDetails(bsq: BlankSizeQuantity) {
    let printerSizeDetail: PrinterSizeDetails = new PrinterSizeDetails;
    printerSizeDetail.cost = undefined;
    printerSizeDetail.qty = bsq.orderQty;
    printerSizeDetail.size = bsq.styleSize;
    return printerSizeDetail;
  }

  determineBlankSupplierName(combination: AllocatedBlankSupplierCombinationDetails) {
    let combinationName = '';
    if (combination.bSupplierDetails && combination.bSupplierDetails.length > 0) {
      combination.bSupplierDetails.forEach(bsd => {
        if (!combinationName.includes(bsd.supplierName)) {
          if (combinationName.length > 0) {
            combinationName = combinationName + ' + '
          }
          combinationName = combinationName + bsd.supplierName;
        }
      });
    }

    combination.combinationName = combinationName;
  }

  determinePrinterCombintionName(combination: AllocatedPrinterCombinationDetails) {
    let combinationName = '';
    if (combination.printerDetails && combination.printerDetails.length > 0) {
      combination.printerDetails.forEach(pd => {
        if (!combinationName.includes(pd.printerName)) {
          if (combinationName.length > 0) {
            combinationName = combinationName + ' + '
          }
          combinationName = combinationName + pd.printerName;
        }
      });
    }

    combination.combinationName = combinationName;
  }

  private addBlankSupplierToCombination(selectedblankSupplier: BlanksSupplier, sc: StyleCosting, counter: number, combination: AllocatedBlankSupplierCombinationDetails) {
    let allocatedBlankSupplierDetails: AllocatedBlankSupplierDetails = new AllocatedBlankSupplierDetails(combination.id);
    if(selectedblankSupplier.currency && selectedblankSupplier.currency!==null){
      allocatedBlankSupplierDetails.currency = selectedblankSupplier.currency;
    }else{
      allocatedBlankSupplierDetails.currency = this.salesOrder.currency;
    }
    allocatedBlankSupplierDetails.supplierId = selectedblankSupplier.id;
    allocatedBlankSupplierDetails.supplierName = selectedblankSupplier.name;
     /*COstType*/
     allocatedBlankSupplierDetails.costTypeDetails=[];
     this.costingService.loadCostTypes('SUPPLIER', 'STYLE_COMBINATION_AVERAGE',allocatedBlankSupplierDetails.currency, allocatedBlankSupplierDetails.supplierId).toPromise().then(response => {
       if (response.responseStatus.status === 'SUCCESS') {
         let costTypeDetails: CostTypeDetail[] = response.responsePayload;
         if (costTypeDetails && costTypeDetails.length > 0) {
          allocatedBlankSupplierDetails.costTypeDetails = costTypeDetails;
         }
       }
     });
     /*COstType*/
    let blankSizeDetails: BlankSizeDetails[] = [];
    sc.blankSizeQty.forEach(bsq => {
      let blankSizeDetail: BlankSizeDetails = this.createBlankSizeDetails(bsq,counter);
      blankSizeDetails.push(blankSizeDetail);
    });
    allocatedBlankSupplierDetails.blankSizeDetails = blankSizeDetails;
    combination.bSupplierDetails.push(allocatedBlankSupplierDetails);
    //this.sharedService.sortListByPropertyName(combination.bSupplierDetails,'supplierName');//Dont do it
    this.determineBlankSupplierName(combination);
  }

   removeBlankSupplierFromCombination(bCombDetails:AllocatedBlankSupplierCombinationDetails, bSupplier:AllocatedBlankSupplierDetails) {
    bCombDetails.bSupplierDetails=bCombDetails.bSupplierDetails.filter(x=>x.supplierId!==bSupplier.supplierId);
    this.determineBlankSupplierName(bCombDetails);
    this.costingHelperService.sanitizeAllocatedBlankSupplier(this.costingAllocation);
    this.calculateAllocationSheet(this.costingAllocation);
  }
  
  removeBlankSupplierCombination(styleCosting:StyleCosting,bCombDetails:AllocatedBlankSupplierCombinationDetails) {
    styleCosting.bSupplierCombDetails=styleCosting.bSupplierCombDetails.filter(x=>x.id!==bCombDetails.id);
    this.costingHelperService.sanitizeAllocatedBlankSupplier(this.costingAllocation);
    this.calculateAllocationSheet(this.costingAllocation);
  }

  

  removePrinterFromCombination(pCombDetails:AllocatedPrinterCombinationDetails, printer:AllocatedPrinterDetails) {
    pCombDetails.printerDetails=pCombDetails.printerDetails.filter(x=>x.printerId!==printer.printerId);
    this.determinePrinterCombintionName(pCombDetails);
    this.costingHelperService.sanitizeAllocatedPrinters(this.costingAllocation);
    this.calculateAllocationSheet(this.costingAllocation);
  }

  removePrinterCombination(styleCosting:StyleCosting,pCombDetails:AllocatedPrinterCombinationDetails) {
    styleCosting.printerCombDetails=styleCosting.printerCombDetails.filter(x=>x.id!==pCombDetails.id);
    this.costingHelperService.sanitizeAllocatedPrinters(this.costingAllocation);
    this.calculateAllocationSheet(this.costingAllocation);
  }

  

  addPrinterCombination(selectedPrinterForComb:Printer[],styleCosting?:StyleCosting){
    if(!this.costingAllocation.allocatedPrinters || this.costingAllocation.allocatedPrinters===null){
      this.costingAllocation.allocatedPrinters=[];
    }
    this.addPrinterToAllocatedPrintersList(selectedPrinterForComb);
    if(!styleCosting){
      this.costingAllocation.styleCosting.forEach(sc=>{
        if(sc.status!=='CANCELLED'){
          this.createPrinterCombinationForStyle(selectedPrinterForComb, sc);
        }
      })
    }else{
      this.createPrinterCombinationForStyle(selectedPrinterForComb, styleCosting);
    }
  }

  private createPrinterCombinationForStyle(selectedPrinterForComb: Printer[], sc: StyleCosting) {
    let combination: AllocatedPrinterCombinationDetails = new AllocatedPrinterCombinationDetails(this.sharedService.newGuid());
    combination.printerDetails = [];
    combination.allocated = true;
    selectedPrinterForComb.forEach(selectedPrinter => {
      this.addPrinterToCombination(combination, selectedPrinter, sc);
    });
    
    let avgPrinterSizeDetails: PrinterSizeDetails[] = [];
    sc.blankSizeQty.forEach(bsq => {
      let avgPrinterSizeDetail: PrinterSizeDetails = this.createPrinterSizeDetails(bsq);
      avgPrinterSizeDetails.push(avgPrinterSizeDetail);
    });
    combination.averagePrinterSizeDetails = avgPrinterSizeDetails;
    if (!sc.printerCombDetails || sc.printerCombDetails === null) {
      sc.printerCombDetails = [];
    }
    sc.printerCombDetails.push(combination);
   
  }
  
  lastSaveStatus:string='UNKNOWN';
  private addPrinterToCombination(combination: AllocatedPrinterCombinationDetails, selectedPrinter: Printer, sc: StyleCosting) {
    let allocatedPrinterDetails: AllocatedPrinterDetails = new AllocatedPrinterDetails(combination.id);
    if(selectedPrinter.currency && selectedPrinter.currency!==null){
      allocatedPrinterDetails.currency = selectedPrinter.currency;
    }else{
      allocatedPrinterDetails.currency = this.salesOrder.currency;
    }
    allocatedPrinterDetails.printerId = selectedPrinter.id;
    allocatedPrinterDetails.printerName = selectedPrinter.name;
    /*COstType*/
    allocatedPrinterDetails.costTypeDetails = [];
    this.costingService.loadCostTypes('PRINTER', 'STYLE_COMBINATION_AVERAGE', allocatedPrinterDetails.currency, allocatedPrinterDetails.printerId).toPromise().then(response => {
      if (response.responseStatus.status === 'SUCCESS') {
        let costTypeDetails: CostTypeDetail[] = response.responsePayload;
        if (costTypeDetails && costTypeDetails.length > 0) {
          allocatedPrinterDetails.costTypeDetails = costTypeDetails;
        }
      }
    });
    /*COstType*/
    let printerSizeDetails: PrinterSizeDetails[] = [];
    sc.blankSizeQty.forEach(bsq => {
      let printerSizeDetail: PrinterSizeDetails = this.createPrinterSizeDetails(bsq);
      printerSizeDetails.push(printerSizeDetail);
    });
    allocatedPrinterDetails.printerSizeDetails = printerSizeDetails;

    combination.printerDetails.push(allocatedPrinterDetails);
    this.determinePrinterCombintionName(combination);
  }

  validateCostingAllocationForm(){
    let retVal:NodeListOf<Element>=document.querySelectorAll(".is-invalid");
    if(retVal && retVal.length>0){
      this.lastSaveStatus='INVALID'
      this.toastrService.error('Please fix highlighted errors');
      let firstElement = (retVal[0] as HTMLElement)
      firstElement.focus();
    }else{
      let retValNg:NodeListOf<Element>=document.querySelectorAll(".ng-invalid");
      if(retValNg && retValNg.length>0){
        this.lastSaveStatus='INVALID'
        this.toastrService.error('Please fix highlighted errors');
        let firstElement = (retValNg[0] as HTMLElement)
        firstElement.focus();
      }
    }
  }

  async saveCostingAllocation(){
    this.lastSaveStatus='UNKNOWN'
    let retVal:NodeListOf<Element>=document.querySelectorAll(".is-invalid");
    if(retVal && retVal.length>0){
      this.lastSaveStatus='INVALID'
      this.toastrService.error('Please fix highlighted errors');
      let firstElement = (retVal[0] as HTMLElement)
      firstElement.focus();
    }else{
      if(this.costingAllocation.invalid){
        this.costingAllocation.invalid=false;
      }
      this.costingService.calculateTotalOrderProfit(this.costingAllocation);// This will reset previously saved data
      await this.costingService.saveAllocationSheet(this.salesOrderId,this.costingAllocation).toPromise().then(response=>{
        if (response.responseStatus.status === 'SUCCESS') {
          this.editable=false;
          let costingAllocation: CostingAllocation = response.responsePayload as CostingAllocation;
          this.costingAllocation=costingAllocation;
          this.toastrService.success('Costing Allocation Saved');
          this.lastSaveStatus='SUCCESS'
        }else{
          this.lastSaveStatus='FAILED'
        }
      },err=>{
        this.lastSaveStatus='ERROR';
      });
    }
  }

  async saveCostingAllocationAsDraft(){
    this.costingAllocation.invalid=true;
      await this.costingService.saveAllocationSheet(this.salesOrderId,this.costingAllocation).toPromise().then(response=>{
        if (response.responseStatus.status === 'SUCCESS') {
          this.editable=false;
          let costingAllocation: CostingAllocation = response.responsePayload as CostingAllocation;
          this.costingAllocation=costingAllocation;
          this.toastrService.success('Draft Saved');
        }else{
          this.toastrService.error('Draft Failed');
        }
      },err=>{
        this.toastrService.error('Error while saving the draft');
      });
    
  }

 
  onPrinterStyleInclusion(event:any,printerCombo:AllocatedPrinterCombinationDetails){
    if (event.target.checked) {
      printerCombo.allocated=true;
   } else {
    printerCombo.allocated=false;
    }
  }

  onPrinterFullPackageSelection(event:any,styleCosting:StyleCosting,printerCombo:AllocatedPrinterCombinationDetails){
    if (event.target.checked) {
     this.onPrinterFullPackageChange(true,styleCosting,printerCombo);
   } else {
    this.onPrinterFullPackageChange(false,styleCosting,printerCombo);
    }
  }
  private onPrinterFullPackageChange(fullPacakge: boolean, styleCosting: StyleCosting, printerCombo: AllocatedPrinterCombinationDetails) {
    if (fullPacakge) {
      printerCombo.fullPackage = true;
    } else {
      printerCombo.fullPackage = false;
    }
    printerCombo.printerDetails.forEach(pd => {
      pd.fullPackage = printerCombo.fullPackage;
    });

    this.calculateAllocationSheet(this.costingAllocation);
  }

  
  

 
  onBlankSupplierStyleInclusion(event:any,bSupplierCombo:AllocatedBlankSupplierCombinationDetails){
    if (event.target.checked) {
      bSupplierCombo.allocated=true;
   } else {
    bSupplierCombo.allocated=false;
    }
  }

  calculateAllocationSheet(calculateAllocationSheet:CostingAllocation){
    this.costingService.calculateAllocationSheet(calculateAllocationSheet);
    this.generateCostingSheetsCombinationForEachStyle();
  }

  tableArrowMove(currentId:string,prefix:string,direction:string){
     //console.log('Table arro move', currentId,prefix);
    let retVal:NodeListOf<Element>=document.querySelectorAll("[id^='"+prefix+"']");
    //console.log('Query Selector All',retVal);
    let arrTemp:any[]=[];
    retVal.forEach(element => {
      arrTemp.push(element.id);
    });
    let currIndex=arrTemp.indexOf(currentId);
    //console.log('Current Index',currIndex,currentId);
    let newElement:HTMLElement;
    if(direction==='DOWN' && currIndex!==(arrTemp.length-1)){
      newElement=document.getElementById(arrTemp[currIndex+1]);
    }
    if(direction==='UP' && currIndex!==0){
      newElement=document.getElementById(arrTemp[currIndex-1]);
    }
    
    if(newElement){
      //log('New Element',newElement.id);
      newElement.focus();
      if(newElement instanceof HTMLInputElement){
        newElement.select();
      }
    }
  }

  tableMove(event:any,name:string,prefix:string){
    //log('Key Pressed',event.key);
    if(event.key==='ArrowUp'){
      this.tableArrowMove(name,prefix,'UP');
    }if(event.key==='ArrowDown'){
      this.tableArrowMove(name,prefix,'DOWN');
    }
    /*let currentEl=document.getElementById(name);
    if(currentEl){
      let suffix=name.replace(prefix,'');
      if(suffix && suffix.length>0){
        let suffixLength=suffix.length;
        let suffixInt=parseInt(suffix);
        if(!isNaN(suffixInt)){
          let newName='';
          let newSuffixInt=suffixInt+range;
          if((newSuffixInt+'').length!==suffixLength){
            
            newName=prefix+String(newSuffixInt).padStart(suffixLength, '0');
          }else{
            newName=prefix+newSuffixInt;
          }
            //
          
          let newElement=document.getElementById(newName);
          if(newElement){
            newElement.focus();
            if(newElement instanceof HTMLInputElement){
              newElement.select();
            }
          }else{
            if(anotherRegionRange && !tryAnotherRegion){
              this.tableMove(name,prefix,anotherRegionRange,undefined,true);
            }
          }
        }
      }
    }*/
  }

  isBlankAllocationMismatch(styleCosting:StyleCosting,bSupplierCombDetails:AllocatedBlankSupplierCombinationDetails, blankSizeDetails:BlankSizeQuantity){
    let retVal=false;
    if(/*styleCosting.status!=='CANCELLED'*/bSupplierCombDetails.averageBlankSizeDetails){
      let arr= bSupplierCombDetails.averageBlankSizeDetails.forEach(avgBsd => {
        
        if(avgBsd.size===blankSizeDetails.styleSize && avgBsd.qty!==blankSizeDetails.totalBlankQty){
          retVal=true;
        }
      });
    }
    return retVal;
  }

  mismatchBlankAllocationCount(styleCosting:StyleCosting,bSupplierCombDetails:AllocatedBlankSupplierCombinationDetails, blankSizeDetails:BlankSizeQuantity){
    let retVal="";
    if(/*styleCosting.status!=='CANCELLED'*/bSupplierCombDetails.averageBlankSizeDetails){
      let arr= bSupplierCombDetails.averageBlankSizeDetails.forEach(avgBsd => {
        
        if(avgBsd.size===blankSizeDetails.styleSize && avgBsd.qty!==blankSizeDetails.totalBlankQty){
          retVal=" : "+(blankSizeDetails.totalBlankQty - avgBsd.qty);
        }
      });
    }
    return retVal;
  }

  jobTypeChanged(printer:AllocatedPrinterDetails , printerCombo:AllocatedPrinterCombinationDetails){
    if(printerCombo && printerCombo.printerDetails.length>1 && printer.jobType==='Printing'){
      let arr=printerCombo.printerDetails.filter(x=>x.jobType==='Printing');
      if(arr.length>1){
        setTimeout(()=>{
          printer.jobType=undefined;
        },300);
        
      }
    }
  }

  initalDeliveryChanged(printerCombo:AllocatedPrinterCombinationDetails){
    if(printerCombo.printerDetails && printerCombo.printerDetails.length>0){
      printerCombo.printerDetails.forEach(pd => {
        pd.initialBlankDelivery=false;
      });
    }
  
    if(printerCombo.intialDeliveryPrinterId && printerCombo.intialDeliveryPrinterId!==null){
      printerCombo.printerDetails.forEach(pd => {
        if(pd.printerId===printerCombo.intialDeliveryPrinterId){
          pd.initialBlankDelivery=true;
        }
      });
    }
  }

  sanitizeBlankCode(blankSizeDetails:BlankSizeDetails){
    if( blankSizeDetails && blankSizeDetails.blankCode && blankSizeDetails.blankCode.trim().length>0){
      blankSizeDetails.blankCode= blankSizeDetails.blankCode.trim().toUpperCase();
      if(!blankSizeDetails.blankCode.startsWith('CL-BL-')){
        blankSizeDetails.blankCode='CL-BL-'+blankSizeDetails.blankCode;
      }
    }

  }
  /*propogation Starts*/
  propogateBlankCode(bSupplierDetail:AllocatedBlankSupplierDetails,blankCode:string){
    bSupplierDetail.blankSizeDetails.forEach(bsd => {
      if((!bsd.blankCode || bsd.blankCode===null || bsd.blankCode==='') && bsd.qty>0){
        bsd.blankCode=blankCode;
        this.sanitizeBlankCode(bsd);
      }
    });
  }
  propogateBlankCost(bSupplierDetail:AllocatedBlankSupplierDetails,blankCost:number){
    bSupplierDetail.blankSizeDetails.forEach(bsd => {
      if((!bsd.cost || bsd.cost===0) && bsd.qty>0){
        bsd.cost=blankCost;
      }
    });
  }

  propogatePrinterCost(printer:AllocatedPrinterDetails,printCost:number){
    printer.printerSizeDetails.forEach(psd=>{
      if((!psd.cost || psd.cost===0) && psd.qty>0){
        psd.cost=printCost;
      }
    });
  }
  /*propogation Ends*/

  generateCostingSheetsCombinationForEachStyle(savePostGenerate?:boolean){
    this.costingService.generateCostingSheetsCombinationForEachStyle(this.salesOrder,this.costingAllocation,
      this.freshCostTypeDetailListForPrinterTopLevel,
      this.freshCostTypeDetailListForPrinterStyleLevel,
      this.freshCostTypeDetailListForSupplierTopLevel,
      this.freshCostTypeDetailListForSupplierStyleLevel,
      this.freshCostTypeDetailListForOrganization,
      this.freshCostTypeDetailListForCustomer,
      this.freshCostTypeDetailListForHeaders
    );
    if(savePostGenerate){
      this.saveCostingAllocation();
    }
  }

   handleThumbnailError(productAttachment: ProductAttachment, event) {
      if (productAttachment) {
         if (isNaN(productAttachment?.thumbnailErrorCount)) {
            productAttachment.thumbnailErrorCount = 0;
         }
         event.target.src = productAttachment.fileSignedUrl;
         var x: number = productAttachment.thumbnailErrorCount;
         productAttachment.thumbnailErrorCount = x + 1;
         if (productAttachment.thumbnailErrorCount < 2) {
            event.target.src = productAttachment.fileSignedUrl;
            productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
         } else {
            event.target.src = "/assets/default-image.jpg"
         }
      } else {
         event.target.src = "/assets/default-image.jpg"
      }
   }

   private changeCostTypeCurrency(ctdList: CostTypeDetail[], currency: string) {
     if(this.editable){
      if (ctdList && ctdList.length > 0) {
         ctdList.forEach(ctd => {
            ctd.currency = currency;
         });
      }
      
    }
   }
   private changeCurrency(object:any,property:string,newCurrency:string){
     if(this.editable){
        object[property]=newCurrency;
     }
   }

  changePrinterCurrency(srcPrinter: AllocatedPrinterDetails,newCurrency: string) {
    if (this.editable) {
      this.changeCurrency(srcPrinter,'currency',newCurrency);
      this.changeCostTypeCurrency(srcPrinter.costTypeDetails,newCurrency);
      if(this.costingAllocation && this.costingAllocation.styleCosting && this.costingAllocation.styleCosting.length>0){
        this.costingAllocation.styleCosting.forEach(sc => {
            if(sc.printerCombDetails && sc.printerCombDetails!==null && sc.printerCombDetails.length>0){  
              sc.printerCombDetails.forEach(pComb => {
                pComb.printerDetails.forEach(printerDetail => {
                  if(printerDetail.printerId===srcPrinter.printerId){
                    this.changeCurrency(printerDetail,'currency',newCurrency);
                    this.changeCostTypeCurrency(printerDetail.costTypeDetails,newCurrency);
                  }
                });
              });
            }
        });
      }
      this.calculateAllocationSheet(this.costingAllocation);
    }
  }
  changeBlankSupplierCurrency(srcBlankSupplier: AllocatedBlankSupplierDetails,newCurrency: string) {
    if (this.editable) {
      this.changeCurrency(srcBlankSupplier,'currency',newCurrency);
      this.changeCostTypeCurrency(srcBlankSupplier.costTypeDetails,newCurrency);
      if(this.costingAllocation && this.costingAllocation.styleCosting && this.costingAllocation.styleCosting.length>0){
        this.costingAllocation.styleCosting.forEach(sc => {
            if(sc.bSupplierCombDetails && sc.bSupplierCombDetails!==null && sc.bSupplierCombDetails.length>0){  
              sc.bSupplierCombDetails.forEach(bComb => {
                bComb.bSupplierDetails.forEach(bSupplierDetail => {
                  if(bSupplierDetail.supplierId===srcBlankSupplier.supplierId){
                    this.changeCurrency(bSupplierDetail,'currency',newCurrency);
                    this.changeCostTypeCurrency(bSupplierDetail.costTypeDetails,newCurrency);
                  }
                });
              });
            }
        });
      }
      this.calculateAllocationSheet(this.costingAllocation);
    }
  }

  costingSheetApprovalChanged(styleCosting: StyleCosting, costingSheet: CostingSheet){
    if(styleCosting.approvedCostingSheetId 
      && styleCosting.approvedCostingSheetId!==null 
      && styleCosting.approvedCostingSheetId!==costingSheet.id 
      ){
        if(confirm("This style was already approved for ( "+ styleCosting.approvedCostingSheetDesc +" ). Do you really want to override?")){
          this.costingSheetApprovalChangedPostValidation(styleCosting,costingSheet);
        }
      }else{
        this.costingSheetApprovalChangedPostValidation(styleCosting,costingSheet);
      }
  }

  private costingSheetApprovalChangedPostValidation(styleCosting: StyleCosting, costingSheet: CostingSheet) {
    if (this.costingAllocation.approvalStatus !== 'APPROVED') {
      let existingStatus = costingSheet.approvalStatus;
      let newStatus: string;
      if (!existingStatus || existingStatus === null || existingStatus === 'NOT_APPROVED') {
        newStatus = 'APPROVED';
      } else {
        newStatus = 'NOT_APPROVED';
      }

      styleCosting.costingSheets.forEach(cs => {
        cs.approvalStatus = 'NOT_APPROVED';
      });
      costingSheet.approvalStatus = newStatus;
      this.costingService.calculateTotalOrderProfit(this.costingAllocation);
      this.reloadBlankSummaryPreview();
    }
   }

   reloadBlankSummaryPreview(){
      
      if(this.salesOrder.productionStatus===this.productionStatusForCostingPendingApproval || this.salesOrder.productionStatus===this.productionStatusForCostingPending){
         this.blankSummaryEventsSubject.next('RELOAD');
      }
   }
   shiftFHandler(type:string){
     //alert('Shift+ '+type);
   }

  async approveSalesOrderCosting() {
    if(!this.costingService.isAllStylesApproved(this.costingAllocation)){
      this.toastrService.error('All styles are not approved. Please approve all styles');
    } else{
      this.costingAllocation.styleCosting.forEach(sc => {
      if (sc.status !== 'CANCELLED') {
        //reset previous approval if any and set approval for right combinations
        sc.costingSheets.forEach(cs => {
          if (cs.approvalStatus === 'APPROVED') {
            sc.approvedCostingSheetId=cs.id;
            sc.approvedCostingSheetDesc= cs.allocatedPrinterNames + ' / ' + cs.allocatedBlankSupplierNames;
            let approvedPrinterCombId = cs.printerCombId;
            let approvedSupplierCombId = cs.supplierCombId;
            if (sc.bSupplierCombDetails && sc.bSupplierCombDetails != null && sc.bSupplierCombDetails.length > 0) {
              sc.bSupplierCombDetails.forEach(bComb => {
                bComb.approvalStatus = 'NOT_APPROVED';
                if (approvedSupplierCombId === bComb.id) {
                  bComb.approvalStatus = 'APPROVED';
                }
              });
            }
            if (sc.printerCombDetails && sc.printerCombDetails != null && sc.printerCombDetails.length > 0) {
              sc.printerCombDetails.forEach(pComb => {
                pComb.approvalStatus = 'NOT_APPROVED';
                if (approvedPrinterCombId === pComb.id) {
                  pComb.approvalStatus = 'APPROVED';
                }
              });
            }
          }
        });
      }
    });

    let previousStatus = this.costingAllocation.approvalStatus;
    this.costingAllocation.approvalStatus = 'APPROVED';
    await this.saveCostingAllocation();
    if (this.lastSaveStatus === 'SUCCESS') {
      await this.saveProductionStatusChange(this.salesOrder, this.salesHelperService.productionStatusForCostingApproved).then(res => {
        this.statusChanged.emit(this.salesHelperService.productionStatusForCostingApproved);
        if(this.salesOrder.orderType === 'Estimation'){
            // route after sales order recalculation in parent component
        }else{
            this.router.navigate(['costing/list/pendingApproval']);
        }
      });
    }/*else{
        this.costingAllocation.approvalStatus=previousStatus;
        this.toastrService.error('Error in setting the Costing Approval');
      }*/
    }
  }

   async submitCostingForApproval(){
    
    let validityRes=this.costingService.validateCostingAllocation(this.costingAllocation);
     if(validityRes.status==='INVALID'){
       let errMessage='';
       validityRes.reasons.forEach(reason => {
        errMessage = errMessage + '<br>' + reason;
     });
       this.toastrService.error('<span style="font-size:10px !important;">Error in Allocation: '+'<br>'+errMessage +'</span>','',{ enableHtml: true});
       return;
     }

     
     await this.saveProductionStatusChange(this.salesOrder,this.salesHelperService.productionStatusForCostingPendingApproval).then(res=>{
      this.statusChanged.emit(this.salesHelperService.productionStatusForCostingPendingApproval);
        if (this.salesOrder.orderType === 'Estimation') {
           this.router.navigate(['estimation/pending/list']);
        } else {
           this.router.navigate(['costing/list/pending']);
        }
     });
     
   
  }
   async unapproveSalesOrderCosting(){
    let previousStatus=this.costingAllocation.approvalStatus;
    this.costingAllocation.approvalStatus='NOT_APPROVED';
    await this.saveCostingAllocation();
      if(this.lastSaveStatus==='SUCCESS'){
        let reason:string='Re-Costing Required';
        if (this.salesOrder.orderType === 'Estimation') {
          reason='Re-Estimation Required';
        }
        await this.saveProductionStatusChange(this.salesOrder,this.salesHelperService.productionStatusForCostingPending,reason).then(res=>{
          this.statusChanged.emit(this.salesHelperService.productionStatusForCostingPending);
           if (this.salesOrder.orderType === 'Estimation') {
              this.router.navigate(['estimation/approval/pending/list']);
           } else {
              this.router.navigate(['costing/list/pendingApproval']);
           }
         });
      }else{
        this.costingAllocation.approvalStatus=previousStatus;
        this.toastrService.error('Error in setting the Costing Approval');
      }
    
   }

   async saveProductionStatusChange(salesOrder:SalesOrder,newStatus:string,statusReason?:string,statusNote?:string) {
    salesOrder.productionStatus=newStatus;
    if (salesOrder.productionStatus && salesOrder.productionStatus !== null) {
       await this.salesHelperService.saveProductionStatusChange(salesOrder, newStatus,statusReason,statusNote).then(async () => {
        await this.getCostingAllocation(this.salesOrderId);
       }, err => {
          this.toastrService.error('Error while updating Production Status : ' + JSON.stringify(err));
       });
    }
 }

   getUserInputIndex(costTypeDetails:CostTypeDetail[]){
     if(!this.userInputStyleCostIndex){
     let retVal:number=0;
     if(costTypeDetails && costTypeDetails.length>0){
       for (let index = 0; index < costTypeDetails.length; index++) {
         const ctd = costTypeDetails[index];
         if(ctd.source==='USER_INPUT'){
          retVal=index;
          break;
         }
         
       }
     }
     this.userInputStyleCostIndex= retVal;
    }
    return this.userInputStyleCostIndex;

   }

   getUserInputCostTypeDetailsCount(costTypeDetails:CostTypeDetail[]):number{
    if(costTypeDetails && costTypeDetails.length>0){
      let arr=costTypeDetails.filter(x=>x.source==='USER_INPUT');
      return arr.length;
    }else{
      return 0;
    }

   }
   
/*NOtes*/
description:string;
   getEnterCount(str: string, max: number,defaultCount:number) {
    if (str && str !== null) {
      let rowCount = str.split(/\r\n|\r|\n/).length;
      if (rowCount > max) {
        return max;
      } else {
        return rowCount;
      }
    } else {
      return defaultCount;
    }
  }
/*Notes Ends*/

audit(val:any){
//console.log(val);
}

calculateExtraQty(sc:StyleCosting,extraQtyPercentage:number){
  this.costingService.calculateExtraQty(sc,extraQtyPercentage);
  this.reCalculateExtraBlanks(sc);
  
}
manualExtraQtyChanged(sc:StyleCosting){
  this.reCalculateExtraBlanks(sc);
}

  private reCalculateExtraBlanks(sc: StyleCosting) {
    this.calculateAllocationSheet(this.costingAllocation);
    if (sc.bSupplierCombDetails && sc.bSupplierCombDetails.length > 0) {
      sc.bSupplierCombDetails.forEach(bComb => {
        if (bComb && bComb.bSupplierDetails && bComb.bSupplierDetails.length === 1) {
          let supplier = bComb.bSupplierDetails[0];
          if (supplier && supplier.blankSizeDetails && supplier.blankSizeDetails.length > 0) {
            sc.blankSizeQty.forEach(bsq => {
              supplier.blankSizeDetails.forEach(bsd => {
                if (bsd.size === bsq.styleSize) {
                  bsd.qty = bsq.totalBlankQty;
                }
              });
            });
          }
        }
      });
      this.calculateAllocationSheet(this.costingAllocation);
    }
  }

   openOrderDetailModal(sc: StyleCosting, sci: number) {
      let orderDetailSelected: OrderDetail = this.getOrderDetailById(sc.orderDetailId);
      const confirmDialog = this.dialog.open(OrderDetailViewerDialogComponent, {
         disableClose: false,
         width: '100vw',
         maxWidth: '90vw',
         minWidth: '90vw',
         minHeight: '80%',
         data: {
            salesOrder: this.salesOrder,
            orderDetail: orderDetailSelected,
            orderDetailIndex: sci,
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {

         } else {

         }
      });
   }

   openSkuPrinterAssignmentModal(skuPrinterAssignmentList:any[],sc:StyleCosting) {
   // let orderDetailSelected: OrderDetail = this.getOrderDetailById(sc.orderDetailId);
    const confirmDialog = this.dialog.open(SkuPrinterAssignmentModalComponent, {
       disableClose: false,
       width: '100vw',
       maxWidth: '90vw',
       minWidth: '90vw',
       minHeight: '80%',
       data: {
          //salesOrder: this.salesOrder,
          skuPrinterAssignmentList: skuPrinterAssignmentList,
          title:'Repeat Order Details for '+sc.sku

       }
    });
    confirmDialog.afterClosed().subscribe(res => {
       if (res) {

       } else {

       }
    });
 }


   populatePreProSampleApprovedPrinter(ca: CostingAllocation) {
      if (ca && ca.styleCosting && ca.styleCosting.length > 0) {
         ca.styleCosting.forEach(existingSc => {
            let preProSamplePrinterId: string = existingSc.product.preProSampleApprovedPrinterId;
            let printerList: Printer[] = this.costingService.getPrinterList();
            if (printerList !== null && printerList !== undefined) {
               let printer: Printer = printerList.find(x => x.id === preProSamplePrinterId);
               existingSc.preProSampleApprovedPrinter = printer?.name;
            }
         });
      }
   }

   showRepeatOrderDetails(salesOrder:SalesOrder,styleCosting:StyleCosting){
    this.salesService.getRepeatOrderDetails(salesOrder.customerId,styleCosting.productId).subscribe(response=>{
      if (response.responseStatus.status === 'SUCCESS') {
        let repeatOrderDetails:any[] = response.responsePayload;
        if(repeatOrderDetails && repeatOrderDetails!=null && repeatOrderDetails.length>0){
          repeatOrderDetails=repeatOrderDetails.filter(x=>x.salesOrderId!==salesOrder.id);
        }
        this.openSkuPrinterAssignmentModal(repeatOrderDetails,styleCosting);
     }else{
      this.toastrService.error(response.responseStatus.message);
     }
    },err=>{
      this.toastrService.error('Error while displaying Repeat Order Details');
    });
   }
/*
   showMismatchWithRepeatOrderDetails(salesOrder:SalesOrder,styleCosting:StyleCosting){
    if(styleCosting.repeatOrder && !styleCosting.repeatOrderAnalysisStatus){
      this.salesService.getRepeatOrderDetails(salesOrder.customerId,styleCosting.productId).subscribe(response=>{
        if (response.responseStatus.status === 'SUCCESS') {
          let repeatOrderDetails:any[] = response.responsePayload;
          if(repeatOrderDetails && repeatOrderDetails!=null && repeatOrderDetails.length>0){
            repeatOrderDetails=repeatOrderDetails.filter(x=>x.salesOrderId!==salesOrder.id);
            styleCosting.repeatOrderDetails=repeatOrderDetails;
          }
          styleCosting.repeatOrderAnalysisStatus=true;
          this.analyzeReportOrderDiff(salesOrder,styleCosting);
       }
      });
    }
    return styleCosting.repeatOrderAnalysis;
   }

  analyzeReportOrderDiff(salesOrder: SalesOrder, styleCosting: StyleCosting) {
    let approvedCostingSheetArr:CostingSheet[]= styleCosting.costingSheets.filter(x=>x.approvalStatus==='APPROVED');
    if(approvedCostingSheetArr && approvedCostingSheetArr.length>0){
        let approvedCS=approvedCostingSheetArr[0];

        console.log('approvedCS', approvedCS.supplierCombId);

        styleCosting.repeatOrderAnalysisStatus=true;
        styleCosting.repeatOrderAnalysis="SOme Error";

    }
  }*/

   toggleFullPackageForAllStyles(allocatedPrinter:AllocatedPrinterDetails,costingAllocation:CostingAllocation,toggle:boolean){
    if(costingAllocation && costingAllocation.styleCosting && costingAllocation.styleCosting.length>0){
      costingAllocation.styleCosting.forEach(sc => {
        if (sc.printerCombDetails && sc.printerCombDetails != null && sc.printerCombDetails.length > 0) {
          sc.printerCombDetails.forEach(pComb => {
            if(pComb.printerDetails && pComb.printerDetails.length===1){
              if(pComb.printerDetails[0] && pComb.printerDetails[0].printerId===allocatedPrinter.printerId){
                this.onPrinterFullPackageChange(toggle,sc,pComb);
              }
            }
            
          });
        }
      });
    }
   }

   /******************* START: COST ESTIMATION CHANGES ********************** */
   onKeyupOrderQuantityChange(event: any, orderQty: number, sc: StyleCosting, size: string) {

      if (this.salesOrder && this.salesOrder.orderType === 'Estimation') {
         //console.log('onKeyupOrderQuantityChange= ', orderQty, sc, size);

         // Update orderQty for each PrinterSize Details
         if (sc.printerCombDetails && sc.printerCombDetails.length > 0) {
            sc.printerCombDetails.forEach(pc => {
               pc.printerDetails.forEach(pd => {
                  pd.printerSizeDetails.forEach(psdl => {
                     if (size === psdl.size) {
                        psdl.qty = orderQty;
                     }
                  });
               });
            });
         }

         // Update orderQty for each Blanks Supplier Size Details
         // NOT REQUIRED
         /*
         if (sc.bSupplierCombDetails && sc.bSupplierCombDetails.length > 0) {
            sc.bSupplierCombDetails.forEach(bComb => {
               if (bComb && bComb.bSupplierDetails && bComb.bSupplierDetails.length > 0) {
                  bComb.bSupplierDetails.forEach(bsd => {
                     bsd.blankSizeDetails.forEach(bbsd => {
                        if (size === bbsd.size) {
                           bbsd.qty = orderQty;
                        }
                     });
                  });
               }
            });
         }         
         */

         // Propogate the Quantity Update to Style Costing
         if (sc) {
            let styleOrderQtyPrevious: number = sc.orderQty;
            let styleTotalQtyPrevious: number = sc.totalQty;
            let styleTotalQtyNew: number = 0;
            sc.blankSizeQty.forEach(bsq => {
               if (!isNaN(bsq.orderQty)) {
                  styleTotalQtyNew = styleTotalQtyNew + bsq.orderQty;
               }
            });
            sc.orderQty = styleOrderQtyPrevious + (styleTotalQtyNew - styleTotalQtyPrevious);
            sc.totalQty = styleTotalQtyNew;
         }

         // Propogate the Quantity Update to SalesOrder
         // TODO:
         this.calculateAllocationSheet(this.costingAllocation);
         //console.log(' onKeyupOrderQuantityChange ZZZ = ', this.costingAllocation);
      }
   }

   propogateStyleQuantity(orderQty: number, sc: StyleCosting){
      if(sc.blankSizeQty){
         sc.blankSizeQty.forEach(bsq =>{
            bsq.orderQty=orderQty;
            //console.log('propogateStyleQuantity = ' ,orderQty, sc, bsq.styleSize);
            this.onKeyupOrderQuantityChange(null,orderQty,sc,bsq.styleSize);
         });
      }
    }

    async acknowledgeEstimation(){
    
    
       await this.saveProductionStatusChange(this.salesOrder,this.salesHelperService.productionStatusForEstimationAcknowledgment).then(res=>{
        this.statusChanged.emit(this.salesHelperService.productionStatusForEstimationAcknowledgment);
          if (this.salesOrder.orderType === 'Estimation') {
             this.router.navigate(['estimation/pending/list']);
          } else {
             this.router.navigate(['costing/list/pending']);
          }
       });
       
     
    }
   /******************* END: COST ESTIMATION CHANGES ********************** */

   openCalculator(element: any, propertyName: string, costingAllocation: CostingAllocation) {
      //console.log(element);
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: false,
         data: {
            title: 'Arithmetic Expression Calculator',
            modalType: 'TEXT_INPUT',
            message: 'Enter digits/numbers and arithmetic operations to evaluate expression.', 
            confirmBtnText: 'Ok',
            cancelBtnText: 'Cancel',
            textInputRegex:'^[0-9*+-/()]*$',
            textInputRegexError:'Please enter only digits and arithmetic operations.',
            submitOnEnter: true
         }
      });
      confirmDialog.afterClosed().toPromise().then(async inputData => {
         //console.log('Entered Data =',inputData);
         if (inputData) {
            try {
               let evaluatedData = eval(inputData);
               //console.log('Evaluated Data =',evaluatedData);
               element[propertyName] = this.costingHelperService.roundUp(evaluatedData,3);
               this.calculateAllocationSheet(costingAllocation);
             } catch (e) {
               //console.log(e.message);
               alert(e.message);
             }
         }
      });
   }
   
}
