<h1 mat-dialog-title>{{data.title}}</h1>
<hr>
<div  style="min-width: 30vw;">
  <ng-container *ngIf="!data.skuPrinterAssignmentList || (data.skuPrinterAssignmentList && data.skuPrinterAssignmentList.length===0)">
    <p  class="text-muted">No details found</p>
  </ng-container>  
  <ng-container *ngIf="data.skuPrinterAssignmentList">
    <mat-dialog-content *ngIf="data.skuPrinterAssignmentList && data.skuPrinterAssignmentList.length>0">
      <ng-container>
        <table class="table table-sm table-striped">
          <thead class="thead-dark">
            <tr class="f-13">
              <th class=" text-center align-middle" scope="col">Customer Name</th>
              <th class=" text-center align-middle" scope="col">Printer Name</th>
              <th class=" text-center align-middle">Order#</th>
              <th class=" text-center align-middle">Order Status</th>
              <th class=" text-center align-middle">Order Ship Date</th>
              <th class=" text-center align-middle">Details</th>
              
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data.skuPrinterAssignmentList" class="f-13">
              <td class=" text-center align-middle">{{item.customerName}}</td>
              <td class=" text-center align-middle">{{item.printerName}}</td>
              <td class=" text-center align-middle">{{item.salesOrderNumber}}</td>
              <td class=" text-center align-middle">{{'sales.productionStatus.'+item.productionStatus | transform}}</td>
              <td class="text-center align-middle">{{item.orderShipDate | date:'mediumDate'}}</td>
              <td>
                <table class="table table-sm table-striped">
                  <thead class="thead-dark">
                    <tr class="f-10">
                      <th class=" text-center align-middle" scope="col">Size</th>
                      <th class=" text-center align-middle" scope="col">Qty</th>
                      <th class=" text-center align-middle" scope="col">Currecny</th>
                      <th class=" text-center align-middle">Cost</th>
                      <th class=" text-center align-middle">Job Type</th>
                      <th class=" text-left align-middle" >Blanks Details</th>
                      
                      
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="item.printerSummaryDetail && item.printerSummaryDetail.printerSizeDetails && item.printerSummaryDetail.printerSizeDetails.length>0">
                    <tr *ngFor="let psd of item.printerSummaryDetail.printerSizeDetails" class="f-10">
                      <td class=" text-center align-middle">{{psd.size}}</td>
                      <td class=" text-center align-middle">{{psd.qty}}</td>
                      <td class=" text-center align-middle">{{item.printerSummaryDetail.printerCurrency}}</td>
                      <td class=" text-center align-middle">{{psd.cost}}</td>
                      <td class=" text-center align-middle" *ngIf="!psd.fullPackage && psd.jobType">{{psd.jobType}}</td>
                      <td class=" text-center align-middle" *ngIf="psd.fullPackage">Full Package</td>
                      <td class=" text-center align-middle" *ngIf="!psd.fullPackage && !psd.jobType">Print</td>
                      <td class=" text-left align-middle" style="white-space: pre-line;" *ngIf="!psd.fullPackage">{{getBlanksUsed(psd)}}</td>
                      <td class=" text-left align-middle" *ngIf="psd.fullPackage">&nbsp;</td>
                      
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close class="btn btn-dark">Close</button>
    </mat-dialog-actions>
    
    
  </ng-container>
  

  





  
</div>